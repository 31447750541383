import { z } from 'zod';

export const TransmissionLineSchema = z.object({
  distance: z.number().nullable(),
  voltage: z.number().nullable(),
  owner: z.string().nullable(),
});
export const SubstationSchema = z.object({
  distance: z.number().nullable(),
  min_volt: z.number().nullish(),
  max_volt: z.number().nullish(),
});
export const ParcelsDTO = z.object({
  _id: z.string().uuid(),
  ll_uuid: z.string().uuid().nullish(),
  parcel_number: z.string().nullish(),
  size_acres: z.string().nullish(),
  waters_concern_level: z.number().nullish(),
  environmental_compliance_concern_level: z.number().nullish(),
  species_concern_level: z.number().nullish(),
  state_species_concern_level: z.number().nullish(),
  protected_area_concern_level: z.number().nullish(),
  owner_name: z.string().nullish(),
  parcel_address: z.string().nullish(),
  parcel_address_city: z.string().nullish(),
  parcel_address_state: z.string().nullish(),
  parcel_address_zip: z.string().nullish(),
  mail_address_line1: z.string().nullish(),
  mail_address_city: z.string().nullish(),
  mail_address_state: z.string().nullish(),
  mail_address_zip: z.string().nullish(),
  transmission_lines: z.array(TransmissionLineSchema).nullish(),
  substations: z.array(SubstationSchema).nullish(),
  parcel_type: z.number().default(0),
  dissolved_ids: z.array(z.string().uuid()).nullish(),
  location: z.string().nullish(),
  zoning_code: z.string().nullish(),
  zoning_description: z.string().nullish(),
});
export const ParcelFilterParcelsResponseDTO = z
  .object({
    count: z.number(),
    parcels: z.array(ParcelsDTO),
  })
  .nullable();

export type ParcelsDTO = z.infer<typeof ParcelsDTO>;
export type ParcelFilterParcelsResponseDTO = z.infer<
  typeof ParcelFilterParcelsResponseDTO
>;
