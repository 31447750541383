import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { OldGeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';
import { GeographyState } from '../../geographies';

const SpeciesLocationProperties = z.object({
  a: z.number(),
  f: z.number(),
  j: z.number(),
  s: z.number(),
  t: z.number(),
  y: z.number(),
  Id: z.number(),
  id: z.number(),
  te: z.string(),
  un: z.number(),
  Day: z.number(),
  FID: z.number(),
  KFR: z.string(),
  Sex: z.string().nullable(),
  eoa: z.number(),
  eob: z.number(),
  eoc: z.number(),
  eod: z.number(),
  eou: z.number(),
  eox: z.number(),
  lat: z.number(),
  sub: z.number(),
  AREA: z.number(),
  Base: z.number(),
  CODE: z.number(),
  FID_: z.number(),
  FIPS: z.union([z.string(), z.number()]),
  GNIS: z.string(),
  HUC8: z.string(),
  NAME: z.string().nullable(),
  Name: z.string().nullable(),
  OID_: z.number(),
  SQMI: z.number(),
  TYPE: z.string(),
  Tabb: z.number(),
  UNIT: z.string(),
  Year: z.number(),
  area: z.number(),
  bova: z.string(),
  cesa: z.string(),
  crpr: z.string(),
  'eo a': z.number(),
  'eo b': z.number(),
  'eo c': z.number(),
  'eo d': z.number(),
  'eo u': z.number(),
  'eo x': z.number(),
  fesa: z.string(),
  long: z.number(),
  mtrs: z.string(),
  name: z.string(),
  nest: z.string().nullable(),
  pair: z.string().nullable(),
  plss: z.string().nullable(),
  tier: z.string(),
  type: z.string(),
  unit: z.string(),
  year: z.number(),
  zone: z.string(),
  ACRES: z.number(),
  Acres: z.number(),
  CName: z.string(),
  FCODE: z.number(),
  FDATE: z.object({}).passthrough(),
  FTYPE: z.number(),
  HUC10: z.string(),
  HUC12: z.string(),
  HUC_8: z.string(),
  HUMOD: z.string().nullable(),
  HUMod: z.string(),
  LABEL: z.string(),
  Month: z.number(),
  POLY_: z.number(),
  River: z.string(),
  SNAME: z.string(),
  SName: z.string(),
  SPROT: z.string(),
  Sq_Ft: z.number(),
  State: z.string(),
  TNMID: z.string().nullable(),
  ToHUC: z.string(),
  WATER: z.string(),
  ZONE_: z.number(),
  Z_MAX: z.number(),
  Z_MIN: z.number(),
  cfrpt: z.string(),
  cname: z.string(),
  docid: z.string().nullable(),
  eo_id: z.number(),
  eondx: z.number(),
  genus: z.string(),
  grank: z.string(),
  notes: z.string(),
  obsid: z.union([z.string(), z.number()]),
  parts: z.number(),
  quads: z.string(),
  river: z.string(),
  sname: z.string(),
  sprot: z.string(),
  srank: z.string(),
  tr_EO: z.string(),
  tr_id: z.string(),
  usesa: z.string(),
  Access: z.string().nullable(),
  Des_Tp: z.string().nullable(),
  EST_ID: z.number(),
  FAMILY: z.string(),
  FEDREG: z.string(),
  G_RANK: z.string(),
  Gaz_ID: z.number(),
  HUC_10: z.string(),
  HUC_12: z.string(),
  HUTYPE: z.string(),
  HUType: z.string(),
  LADOPT: z.string(),
  Loc_Ds: z.string().nullable(),
  Loc_Nm: z.string().nullable(),
  ProjID: z.string(),
  SEASON: z.string(),
  SPCODE: z.string(),
  STATES: z.string(),
  STATUS: z.string(),
  S_RANK: z.string(),
  Season: z.string(),
  States: z.string(),
  Sur_no: z.number(),
  Z_MEAN: z.number(),
  Z_ZONE: z.number(),
  agesex: z.string().nullable(),
  cfrsxn: z.string(),
  count_: z.string(),
  elcode: z.string(),
  est_id: z.string(),
  family: z.string(),
  fedreg: z.string(),
  frcite: z.string(),
  frdate: z.string(),
  g_rank: z.string(),
  mapndx: z.string(),
  s_rank: z.string(),
  seg_id: z.string(),
  spcode: z.string(),
  states: z.string(),
  status: z.string(),
  threat: z.string().nullable(),
  ADDRESS: z.string(),
  Agg_Src: z.string().nullable(),
  AltMode: z.number(),
  CNTY_NM: z.string(),
  COMNAME: z.string(),
  Clamped: z.number(),
  Country: z.string(),
  DRAWSEQ: z.number(),
  Details: z.string(),
  FLOWDIR: z.number(),
  GAPCdDt: z.string().nullable(),
  GAP_Sts: z.string().nullable(),
  GIS_Src: z.string().nullable(),
  GNIS_ID: z.union([z.string(), z.number()]).nullable(),
  GSOURCE: z.string(),
  LabelID: z.number(),
  Loc_Own: z.string().nullable(),
  META_ID: z.string(),
  POP1990: z.number(),
  POP1996: z.number(),
  POP2010: z.number(),
  PUBDATE: z.string(),
  SCINAME: z.string(),
  SUBUNIT: z.string().nullable(),
  Snippet: z.string().nullable(),
  Species: z.string(),
  Unit_Nm: z.string().nullable(),
  VIPCODE: z.string(),
  WDPA_Cd: z.number(),
  address: z.string(),
  avlcode: z.number(),
  callist: z.string(),
  cell_id: z.number(),
  cfrpara: z.string(),
  comname: z.string(),
  created: z.string(),
  dateobs: z.string().nullable(),
  elmcode: z.string(),
  elmdate: z.string(),
  elmtype: z.number(),
  eo_id_1: z.number(),
  eocount: z.number(),
  eototal: z.number(),
  fedlist: z.string(),
  general: z.string().nullable(),
  habitat: z.string(),
  keyquad: z.string(),
  lastobs: z.union([z.string(), z.number()]),
  obsdate: z.string(),
  occrank: z.string(),
  occtype: z.string(),
  pos_neg: z.string(),
  pubdate: z.string(),
  sciname: z.string(),
  species: z.string(),
  sppbova: z.string(),
  subunit: z.string(),
  taxagrp: z.string(),
  timeobs: z.string().nullable(),
  tr_Desc: z.string(),
  tr_desc: z.string().nullable(),
  tr_site: z.string().nullable(),
  typeobs: z.string(),
  vipcode: z.string(),
  ACCURACY: z.string().nullable(),
  AREASQKM: z.union([z.string(), z.number()]),
  AREASQMI: z.number(),
  AreaSqKm: z.number(),
  CNTY_NBR: z.number(),
  COG_NAME: z.string().nullable(),
  Category: z.string().nullable(),
  Com_Name: z.string(),
  Comments: z.string().nullable(),
  Corridor: z.string(),
  Date_Est: z.string().nullable(),
  Duration: z.string(),
  Extruded: z.number(),
  GAPCdSrc: z.string().nullable(),
  HU_10_DS: z.string(),
  HU_12_DS: z.string(),
  HasLabel: z.number(),
  IUCNCtDt: z.string().nullable(),
  IUCN_Cat: z.string().nullable(),
  LAST_OBS: z.string(),
  LENGTHKM: z.number(),
  LOADDATE: z.union([z.object({}).passthrough(), z.string()]),
  Latitude: z.number(),
  LoadDate: z.union([z.object({}).passthrough(), z.string()]),
  Loc_Mang: z.string().nullable(),
  MAINPATH: z.number(),
  NAMELSAD: z.string(),
  OBJECTID: z.number(),
  ORIG_FID: z.number(),
  ObjectID: z.number(),
  ObsCount: z.number(),
  Own_Name: z.string().nullable(),
  Own_Type: z.string().nullable(),
  QUADNAME: z.string(),
  RANGE_ID: z.number(),
  SCOMNAME: z.string(),
  STCOFIPS: z.string(),
  SUBBASIN: z.string(),
  Sci_Name: z.string(),
  Shapelen: z.number(),
  Sq_Miles: z.number(),
  Src_Date: z.string().nullable(),
  Srv_Area: z.string().nullable(),
  State_Nm: z.string().nullable(),
  Sursubno: z.number(),
  SymbolID: z.number(),
  TRINITY_: z.number(),
  UNITNAME: z.string(),
  accuracy: z.string(),
  areaname: z.string(),
  cfrpttxt: z.string(),
  cfrtitle: z.string(),
  comments: z.string().nullable(),
  commname: z.string(),
  coordsrc: z.string(),
  counties: z.string(),
  d_Access: z.string().nullable(),
  d_Des_Tp: z.string().nullable(),
  describe: z.string(),
  end_time: z.string(),
  'eo total': z.number(),
  eoextant: z.number(),
  eorecent: z.number(),
  firstobs: z.union([z.string(), z.number()]),
  fme_type: z.string(),
  globalid: z.string(),
  gridcode: z.number(),
  isnoprim: z.string(),
  last_obs: z.string(),
  lifeform: z.string(),
  location: z.string().nullable(),
  numadobs: z.string().nullable(),
  numyoung: z.string().nullable(),
  objectid: z.union([z.string(), z.number()]),
  observer: z.string().nullable(),
  orig_fid: z.number(),
  original: z.string(),
  ownermgt: z.string().nullable(),
  presence: z.string(),
  quadname: z.string(),
  range_id: z.number(),
  scomname: z.string(),
  shape_id: z.number(),
  sitedate: z.string(),
  sppobsid: z.number(),
  synonyms: z.string(),
  tr_desc1: z.string(),
  tr_level: z.string().nullable(),
  tr_state: z.string(),
  unitname: z.string(),
  year_obs: z.string(),
  AREAACRES: z.union([z.string(), z.number()]),
  Access_Dt: z.string(),
  AreaAcres: z.number(),
  Area_SqKm: z.number(),
  BUFF_DIST: z.number(),
  CNTY_FIPS: z.string(),
  CO_CENSUS: z.string().nullable(),
  Collector: z.string(),
  ENTITY_ID: z.number(),
  GEODB_OID: z.number(),
  GIS_Acres: z.number(),
  GNIS_NAME: z.string(),
  GNIS_Name: z.string(),
  HU_10_MOD: z.string(),
  HU_12_MOD: z.string(),
  HU_8_Name: z.string(),
  INNETWORK: z.number(),
  IUCNCtSrc: z.string().nullable(),
  JURISTYPE: z.string(),
  KarstZone: z.number(),
  LabelName: z.string().nullable(),
  LifeStage: z.string().nullable(),
  Longitude: z.number(),
  Mang_Name: z.string().nullable(),
  Mang_Type: z.string().nullable(),
  NCONTRB_A: z.number(),
  PERIMETER: z.number(),
  Perimeter: z.number(),
  PopupInfo: z.string().nullable(),
  REACHCODE: z.string(),
  Shapearea: z.number(),
  Site_Desc: z.string(),
  Site_desc: z.string(),
  Symbolize: z.string(),
  USESA_COM: z.string().nullable(),
  Verticies: z.number(),
  ZONE_DESC: z.string(),
  areagroup: z.string(),
  buff_dist: z.number(),
  caendemic: z.string(),
  cbrreason: z.string(),
  cell_name: z.string(),
  cell_type: z.string(),
  cfrsxntxt: z.string(),
  coordsyst: z.string(),
  d_GAP_Sts: z.string().nullable(),
  dateadded: z.string(),
  eagle_use: z.string(),
  elevation: z.number(),
  entity_id: z.number(),
  'eo extant': z.number(),
  'eo recent': z.number(),
  fedstatus: z.string(),
  gisauthor: z.string(),
  keycounty: z.string(),
  kquadname: z.string(),
  latdd_n83: z.number(),
  londd_n83: z.number(),
  masterowl: z.string(),
  occnumber: z.number(),
  perimeter: z.number(),
  precision: z.string(),
  sensitive: z.string(),
  source_id: z.string(),
  symbology: z.union([z.string(), z.number()]),
  taxagroup: z.string(),
  tr_source: z.string().nullable(),
  txtfeatid: z.string(),
  usesa_com: z.string(),
  waterbody: z.string(),
  ABBR_2CHAR: z.string().nullable(),
  ABBR_4CHAR: z.string().nullable(),
  ABBR_5CHAR: z.string().nullable(),
  Access_Src: z.string().nullable(),
  Area_Acres: z.number(),
  BRITH94RAT: z.number(),
  COG_REGION: z.string().nullable(),
  COOPOFFICE: z.string(),
  COOPOFMORE: z.string().nullable(),
  COUNTY_100: z.number(),
  CO_WIKIPED: z.string(),
  DENR_COUNT: z.number(),
  DENR_DISTR: z.string().nullable(),
  DIV_CONTAC: z.string().nullable(),
  DOT_COUNTY: z.number(),
  DOT_DISTRI: z.number(),
  DOT_DIVISI: z.number(),
  DOT_GROUP_: z.string().nullable(),
  EDIS_REGIO: z.string().nullable(),
  EFFECTDATE: z.string().nullable(),
  FolderPath: z.string(),
  HU_10_GNIS: z.string().nullable(),
  HU_10_NAME: z.string(),
  HU_10_TYPE: z.string(),
  HU_12_GNIS: z.string().nullable(),
  HU_12_NAME: z.string(),
  HU_12_TYPE: z.string(),
  HWY_PATROL: z.string().nullable(),
  LASTUPDATE: z.union([z.object({}).passthrough(), z.string()]).nullable(),
  LEADOFFICE: z.string(),
  METASOURCE: z.string().nullable(),
  MetaSource: z.string().nullable(),
  NAME_LOCAS: z.string().nullable(),
  NonContr_1: z.number(),
  NonContrib: z.number(),
  OBJECTID_1: z.number(),
  OBJECTID_2: z.number(),
  PERMANENT_: z.string(),
  POP10_SQMI: z.number(),
  POP2000PRJ: z.number(),
  POP2010PRJ: z.number(),
  POP_ABBREV: z.string(),
  ProtocolID: z.string(),
  RANGE_NOTE: z.string(),
  RESOLUTION: z.number(),
  SCHS_PERIN: z.string().nullable(),
  SHAPE_AREA: z.union([z.string(), z.number()]),
  SHAPE_Area: z.number(),
  SHAPE_LENG: z.union([z.string(), z.number()]),
  SHAPE_Leng: z.number(),
  SINGLEMULT: z.string(),
  SOURCEDATA: z.string().nullable(),
  SOURCEFEAT: z.string().nullable(),
  SOURCEORIG: z.string().nullable(),
  STATE_ABBR: z.string(),
  STATE_FIPS: z.string(),
  STATE_NAME: z.string(),
  STATUS_ABB: z.string(),
  SUBUNITNAM: z.string().nullable(),
  SUB_REGION: z.string(),
  SeasonCode: z.number(),
  SeasonName: z.string(),
  Shape_Area: z.number(),
  Shape_Le_1: z.number(),
  Shape_Le_2: z.number(),
  Shape_Leng: z.number(),
  Shape_Name: z.string(),
  Shape__Are: z.number(),
  Shape__Len: z.number(),
  SourceData: z.string().nullable(),
  SourceFeat: z.string().nullable(),
  SourceOrig: z.string().nullable(),
  Source_PAI: z.string().nullable(),
  Species_MU: z.string(),
  USESA_STAT: z.string().nullable(),
  VACATEDATE: z.string().nullable(),
  VISIBILITY: z.number(),
  WBAREA_PER: z.string().nullable(),
  'ca endemic': z.string(),
  'cbr reason': z.string(),
  cdfwstatus: z.string().nullable(),
  cfrparatxt: z.string(),
  common_nam: z.string(),
  commonname: z.string(),
  coopoffice: z.string(),
  coopofmore: z.string(),
  d_Category: z.string().nullable(),
  d_IUCN_Cat: z.string().nullable(),
  d_Mang_Nam: z.string().nullable(),
  d_Mang_Typ: z.string().nullable(),
  d_Own_Name: z.string().nullable(),
  d_Own_Type: z.string().nullable(),
  d_State_Nm: z.string().nullable(),
  'date added': z.string(),
  db_descrip: z.string(),
  ecological: z.string().nullable(),
  effectdate: z.string(),
  group_clas: z.string(),
  highestuse: z.string(),
  ismultprim: z.string(),
  isprimdiff: z.string(),
  lastobsyea: z.number(),
  lastupdate: z.string(),
  leadoffice: z.string(),
  listing_st: z.string(),
  locdetails: z.string().nullable(),
  modrundate: z.string(),
  objectid_1: z.number(),
  othrstatus: z.string().nullable(),
  pop_abbrev: z.string(),
  range_note: z.string(),
  rec_crea_1: z.string(),
  rec_create: z.number(),
  rec_last_1: z.number(),
  rec_last_m: z.string(),
  rplantrank: z.string().nullable(),
  scientific: z.string(),
  shape_area: z.number(),
  shape_leng: z.number(),
  singlmulti: z.string(),
  start_time: z.string(),
  statestatu: z.string(),
  status_abb: z.string(),
  subspecies: z.string(),
  subunitnam: z.string(),
  surveydate: z.union([z.string(), z.number()]),
  taxongroup: z.string(),
  threatlist: z.string().nullable(),
  tr_federal: z.string().nullable(),
  tr_state_S: z.string().nullable(),
  track_stat: z.string(),
  usesa_stat: z.string(),
  vacatedate: z.string(),
  Description: z.string(),
  'common name': z.string(),
  common_name: z.string(),
  'county name': z.string(),
  elementcode: z.string(),
  'last update': z.string(),
  species__id: z.string(),
  state_alpha: z.string(),
  statestatus: z.string(),
  xlsx_row_id: z.number(),
  cell_mapcode: z.string(),
  'date changed': z.string(),
  'element code': z.string(),
  eoextirpated: z.number(),
  eohistorical: z.number(),
  eothreatlist: z.number(),
  filegdb_type: z.string(),
  'flora status': z.string(),
  fme_geometry: z.string(),
  microhabitat: z.string(),
  shape_length: z.number(),
  cell_allwater: z.string(),
  'eo extirpated': z.number(),
  'eo historical': z.number(),
  'micro habitat': z.string(),
  primary_state: z.string(),
  seq_govtunits: z.union([z.string(), z.number()]),
  bloomingperiod: z.string(),
  cell_diacritic: z.string(),
  db_description: z.string(),
  elevationlow_m: z.number(),
  scientificname: z.string(),
  shape_geometry: z.string(),
  shapefile_type: z.string(),
  'blooming period': z.string(),
  csv_line_number: z.number(),
  elevationhigh_m: z.number(),
  elevationlow_ft: z.number(),
  multi_reader_id: z.number(),
  'scientific name': z.string(),
  cell_description: z.string(),
  elevationhigh_ft: z.number(),
  fme_feature_type: z.string(),
  usdaplantssymbol: z.string(),
  'elevation low (m)': z.number(),
  last_updated_date: z.string(),
  multi_reader_type: z.string(),
  'elevation high (m)': z.number(),
  'elevation low (ft)': z.number(),
  fullscientificname: z.string(),
  'general occurrence': z.string(),
  'usda plants symbol': z.string(),
  'elevation high (ft)': z.number(),
  'resident occurrence': z.string(),
  'seasonal occurrence': z.string(),
  transect_import__id: z.string(),
  csv_number_of_fields: z.number(),
  eopossiblyextirpated: z.number(),
  'full scientific name': z.string(),
  multi_reader_full_id: z.number(),
  multi_reader_keyword: z.string(),
  'eo possibly extirpated': z.number(),
  csv_total_number_of_columns: z.number(),
  transect_selected_species_id: z.string(),
  transect_imported_location__id: z.string(),
  '__fme_ft_return_value226bbe33-5175-4317-9674-fda05f7a5979': z.string(),
  '__fme_ft_return_value33842db6-91e8-4048-a73b-6341d0a6cc26': z.string(),
  '__fme_ft_return_value59c85ac4-53f1-4973-bd5b-76ade5c69483': z.string(),
});

export const ReportPartSpeciesLocationFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  properties: SpeciesLocationProperties.deepPartial().nullable(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  type: z.string().nullable(),
  occurrence_potential: z.string().nullable(),
  exclusion_area: z.boolean(),
  geographies_states: GeographyState.array(),
  reference: z.object({}).passthrough().nullable(),
  species: z.object({}).passthrough(),
  anonymization: z.object({}).passthrough().nullable(),
  primary: z.boolean(),
  has_intersection_aoi: z.boolean(),
  has_intersection_buffer: z.boolean(),
  has_intersection_external: z.boolean(),
  intersection_percentage: z.number().nullable(),
  geometry: OldGeometrySchema.nullable(),
  source__id: z.string().uuid(),
  transect_feature_type: z.enum(['species_location', 'state_species_location']),
  transect_summary: TransectSummarySchema,
});

export type ReportPartSpeciesLocationFeatureDTO = z.infer<
  typeof ReportPartSpeciesLocationFeatureDTO
>;
