import { z } from 'zod';
import { OldGeometrySchema } from '../../utils/geometry-object-schema';

export const StatesCountiesGeographies = z.object({
  geography__id: z.string(),
  state: z.string(),
  state_abbreviation: z.string(),
  counties: z.array(
    z.object({
      _id: z.string(),
      name: z.string(),
      geometry: OldGeometrySchema.optional(),
      townships_and_incorporated_places: z
        .array(
          z.object({
            _id: z.string(),
            name: z.string(),
            type: z.string(),
          }),
        )
        .nullable(),
    }),
  ),
});

export type StatesCountiesGeographies = z.infer<
  typeof StatesCountiesGeographies
>;
