import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { OldGeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const TransportationProperties = z.object({
  name: z.union([z.string(), z.number()]).nullable(),
  fcode: z.number(),
  ftype: z.number(),
  rttyp: z.string().nullable(),
  predir: z.string().nullable(),
  pretyp: z.string().nullable(),
  sufdir: z.string().nullable(),
  suftyp: z.string().nullable(),
  gnis_id: z.union([z.string(), z.number()]),
  prequal: z.string().nullable(),
  sufqual: z.string().nullable(),
  globalid: z.string(),
  loaddate: z.number(),
  objectid: z.number(),
  geodb_oid: z.number(),
  geodb_sub: z.string(),
  airport_cl: z.number(),
  data_secur: z.number(),
  distributi: z.string(),
  faa_airpor: z.union([z.string(), z.number()]),
  permanent_: z.string(),
  source_dat: z.string(),
  source_des: z.string(),
  source_fea: z.string(),
  source_ori: z.string(),
});

export const ReportPartTransportationFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  properties: TransportationProperties.deepPartial(),
  name: z.string().nullable(),
  mtfcc: z.string().nullable(),
  linearid: z.string().nullable(),
  type: z.string().nullable(),
  geometry: OldGeometrySchema,
  source__id: z.string().uuid(),
  transect_feature_type: z.literal('transportation'),
  transect_summary: TransectSummarySchema,
});

export type ReportPartTransportationFeatureDTO = z.infer<
  typeof ReportPartTransportationFeatureDTO
>;
