import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatTreeModule } from '@angular/material/tree';
import { TransectButtonsModule } from '@transect-nx/ui';
import { SharedModule } from '../../shared/shared.module';
import { MapControlWindowModule } from '../map-control-window/map-control-window.module';
import { BaseLayerControlComponent } from './components/base-layer-control/base-layer-control.component';
import { CoordinatesPopupComponent } from './components/coordinates-popup/coordinates-popup.component';
import { DeleteAllControlComponent } from './components/delete-all-control/delete-all-control.component';
import { ExportKMLComponent } from './components/export-kml-control/export-kml-control.component';
import { FeatureDetailsPopupComponent } from './components/feature-details-popup/feature-details-popup.component';
import { GeocoderControlComponent } from './components/geocoder-control/geocoder-control.component';
import { MeasureAreaControlComponent } from './components/measure-area-control/measure-area-control.component';
import { TransectMarkerComponent } from './components/transect-marker/transect-marker.component';
import { DynamicComponentService } from './services/dynamic-component.service';
import { MapService } from './services/map.service';
import { HighlightParcelService } from './services/highlight-parcel.service';
import { RulerUnitStateService } from './services/ruler-unit-state.service';

@NgModule({
  declarations: [
    BaseLayerControlComponent,
    GeocoderControlComponent,
    FeatureDetailsPopupComponent,
    ExportKMLComponent,
    TransectMarkerComponent,
    DeleteAllControlComponent,
    MeasureAreaControlComponent,
    CoordinatesPopupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatListModule,
    MatMenuModule,
    MatTreeModule,
    TransectButtonsModule,
    ClipboardModule,
    MatExpansionModule,
    MapControlWindowModule,
  ],
  providers: [
    MapService,
    DynamicComponentService,
    HighlightParcelService,
    RulerUnitStateService,
  ],
})
export class MapModule {}
