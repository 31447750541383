import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import * as turf from '@turf/turf';
import { Geometries, GeometryCollection, GeometryObject } from '@turf/turf';
import { LngLatBoundsLike, NavigationControl } from 'mapbox-gl';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MapService } from '../map/services/map.service';

@Component({
  selector: 'ts-form-marketplace-site-map',
  templateUrl: './form-marketplace-site-map.component.html',
  styleUrls: ['./form-marketplace-site-map.component.scss'],
})
export class FormMarketplaceSiteMapComponent
  implements OnDestroy, AfterViewInit
{
  @ViewChild('mapContainer') mapContainer?: ElementRef<HTMLDivElement>;

  @Input() projectId?: string | null;
  @Input() projectGeometry?: GeometryObject | null;

  showWaitCursor = true;

  private destroy$ = new Subject<void>();

  constructor(private mapService: MapService) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.mapService
      .initMap$({
        attributionControl: false,
        container: this.mapContainer?.nativeElement ?? '',
        logoPosition: 'bottom-right',
        style: 'mapbox://sprites/mapbox/satellite-streets-v11',
        zoom: 12,
        accessToken: environment.mapboxToken,
        dragRotate: false,
        refreshExpiredTiles: true,
      })
      .pipe(
        switchMap(() => this.mapService.addBaseLayerControl$()),
        tap((transectMap) => {
          transectMap.addControl(
            new NavigationControl({
              showCompass: true,
              showZoom: true,
              visualizePitch: true,
            }),
            'top-left',
          );

          transectMap.doubleClickZoom.disable();
        }),
        tap(() => {
          this.drawProjectGeometry(
            this.projectGeometry as Geometries | GeometryCollection,
          );
          this.showWaitCursor = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  goToAoi(): void {
    const transectMap = this.mapService.getMap();
    if (transectMap && this.projectGeometry) {
      transectMap.fitBounds(
        turf.bbox(this.projectGeometry) as LngLatBoundsLike,
        {
          padding: 50,
          animate: false,
        },
      );
    }
  }

  private drawProjectGeometry(geometry: Geometries | GeometryCollection): void {
    if (!geometry) {
      return;
    }

    const featureCollection = turf.flatten(geometry);
    const fillColor = '#62969a';

    const transectMap = this.mapService.getMap();

    transectMap?.addSource('project-source', {
      type: 'geojson',
      data: featureCollection,
    });

    transectMap?.addLayer({
      id: 'project-layer',
      source: 'project-source',
      type: 'line',
      paint: {
        'line-color': fillColor,
        'line-width': 2,
      },
    });

    if (geometry && geometry.type !== 'LineString') {
      transectMap?.addLayer({
        id: 'project-layer-fill',
        source: 'project-source',
        type: 'fill',
        paint: {
          'fill-color': fillColor,
          'fill-opacity': 0.5,
        },
      });
    }

    const aoiBBox = turf.bbox(featureCollection);

    if (aoiBBox) {
      transectMap?.fitBounds(aoiBBox as LngLatBoundsLike, {
        animate: false,
        padding: 50,
      });
    }
  }
}
