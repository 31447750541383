import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  combineLatest,
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import {
  BooleanClaim,
  PrimitiveClaim,
  SessionClaim,
  SessionClaimValidator,
} from 'supertokens-web-js/recipe/session';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { FormTypeKey, TransectPlanKey } from '@transect-nx/models';
import { FormLegalAgreementExemptionApiService } from './backend-api/form-legal-agreement-exemption-api.service';
import { FormsApiService } from './backend-api/forms.service';

export class PlatformConfigNumberClaim
  implements SessionClaim<(number | null)[]>
{
  public readonly id: string;
  public readonly refresh: SessionClaimValidator['refresh'];
  public readonly defaultMaxAgeInSeconds: number | undefined;

  validators = {
    hasMoreAvailable: (
      maxAgeInSeconds?: number,
      id?: string,
    ): SessionClaimValidator => {
      return {
        id: id !== undefined ? id : this.id,
        refresh: (ctx) => this.refresh(ctx),
        shouldRefresh: (payload, ctx) => {
          return (
            this.getValueFromPayload(payload, ctx) === undefined ||
            // We know payload[this.id] is defined since the value is not undefined in this branch
            (maxAgeInSeconds !== undefined &&
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              payload[this.id].t < Date.now() - maxAgeInSeconds * 1000)
          );
        },
        validate: (payload, ctx) => {
          const claimVal = this.getValueFromPayload(payload, ctx);
          if (claimVal === undefined) {
            return {
              isValid: false,
              reason: {
                message: 'value does not exist',
                actualValue: claimVal,
              },
            };
          }

          const ageInSeconds =
            (Date.now() - (this.getLastFetchedTime(payload, ctx) ?? 0)) / 1000;
          if (maxAgeInSeconds !== undefined && ageInSeconds > maxAgeInSeconds) {
            return {
              isValid: false,
              reason: {
                message: 'expired',
                ageInSeconds,
                maxAgeInSeconds,
              },
            };
          }

          if (claimVal?.length !== 2) {
            return {
              isValid: false,
              reason: {
                message: 'invalid claim length',
                actualValue: claimVal,
              },
            };
          }

          if (claimVal[1] !== null && (claimVal[0] ?? 0) >= claimVal[1]) {
            return {
              isValid: false,
              reason: {
                message: 'reached or exceeded limit',
                actualValue: claimVal,
              },
            };
          }

          return { isValid: true };
        },
      };
    },
    isEnabled: (
      maxAgeInSeconds?: number,
      id?: string,
    ): SessionClaimValidator => {
      return {
        id: id !== undefined ? id : this.id,
        refresh: (ctx) => this.refresh(ctx),
        shouldRefresh: (payload, ctx) => {
          return (
            this.getValueFromPayload(payload, ctx) === undefined ||
            // We know payload[this.id] is defined since the value is not undefined in this branch
            (maxAgeInSeconds !== undefined &&
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              payload[this.id].t < Date.now() - maxAgeInSeconds * 1000)
          );
        },
        validate: (payload, ctx) => {
          const claimVal = this.getValueFromPayload(payload, ctx);
          if (claimVal === undefined) {
            return {
              isValid: false,
              reason: {
                message: 'value does not exist',
                actualValue: claimVal,
              },
            };
          }

          const ageInSeconds =
            (Date.now() - (this.getLastFetchedTime(payload, ctx) ?? 0)) / 1000;
          if (maxAgeInSeconds !== undefined && ageInSeconds > maxAgeInSeconds) {
            return {
              isValid: false,
              reason: {
                message: 'expired',
                ageInSeconds,
                maxAgeInSeconds,
              },
            };
          }

          if (claimVal?.length !== 2) {
            return {
              isValid: false,
              reason: {
                message: 'invalid claim length',
                actualValue: claimVal,
              },
            };
          }

          if (claimVal[1] === null || claimVal[1] > 0) {
            return { isValid: true };
          }

          return {
            isValid: false,
            reason: {
              message: 'access disabled',
              actualValue: claimVal,
            },
          };
        },
      };
    },
  };

  constructor(config: {
    id: string;
    refresh: (userContext?: any) => Promise<void>;
    defaultMaxAgeInSeconds?: number;
  }) {
    this.id = config.id;
    this.refresh = config.refresh;
    this.defaultMaxAgeInSeconds = config.defaultMaxAgeInSeconds;
  }

  getValueFromPayload(payload: any, _userContext?: any): (number | null)[] {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return payload[this.id] !== undefined ? payload[this.id].v : undefined;
  }

  getLastFetchedTime(payload: any, _userContext?: any): number | undefined {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return payload[this.id] !== undefined ? payload[this.id].t : undefined;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SessionClaimsService {
  readonly TransectPlanClaim = new PrimitiveClaim<TransectPlanKey>({
    id: 'transect_plan',
    refresh: async () => {
      await this.refreshValue('transect_plan');
    },
  });

  readonly PlatformConfigTotalReportLimitClaim = new PlatformConfigNumberClaim({
    id: 'platform_configuration_total_report_limit',
    refresh: async () => {
      await this.refreshValue('platform_configuration_total_report_limit');
    },
  });

  readonly PlatformConfigDistributionHostingCapacityClaim = new BooleanClaim({
    id: 'platform_configuration_distribution_hosting_capacity',
    refresh: async () => {
      await this.refreshValue(
        'platform_configuration_distribution_hosting_capacity',
      );
    },
  });

  readonly PlatformConfigInterconnectionDataTransmissionCapacityClaim =
    new BooleanClaim({
      id: 'platform_configuration_interconnection_data_transmission_capacity',
      refresh: async () => {
        await this.refreshValue(
          'platform_configuration_interconnection_data_transmission_capacity',
        );
      },
    });

  readonly PlatformConfigLocalPermitsClaim = new BooleanClaim({
    id: 'platform_configuration_local_permits',
    refresh: async () => {
      await this.refreshValue('platform_configuration_local_permits');
    },
  });

  readonly PlatformConfigMapAccessClaim = new BooleanClaim({
    id: 'platform_configuration_map_access',
    refresh: async () => {
      await this.refreshValue('platform_configuration_map_access');
    },
  });

  readonly PlatformConfigMarketplaceAccessClaim = new BooleanClaim({
    id: 'platform_configuration_marketplace_access',
    refresh: async () => {
      await this.refreshValue('platform_configuration_marketplace_access');
    },
  });

  readonly PlatformConfigMonthlyReportLimitClaim =
    new PlatformConfigNumberClaim({
      id: 'platform_configuration_monthly_report_limit',
      refresh: async () => {
        await this.refreshValue('platform_configuration_monthly_report_limit');
      },
    });

  readonly PlatformConfigParcelSearchExportLimitClaim =
    new PlatformConfigNumberClaim({
      id: 'platform_configuration_parcel_search_export_limit',
      refresh: async () => {
        await this.refreshValue(
          'platform_configuration_parcel_search_export_limit',
        );
      },
    });

  readonly PlatformConfigReportMapLayersExportClaim = new BooleanClaim({
    id: 'platform_configuration_report_map_layers_export',
    refresh: async () => {
      await this.refreshValue(
        'platform_configuration_report_map_layers_export',
      );
    },
  });

  readonly PlatformConfigReportPdfExportClaim = new BooleanClaim({
    id: 'platform_configuration_report_pdf_export',
    refresh: async () => {
      await this.refreshValue('platform_configuration_report_pdf_export');
    },
  });

  readonly PlatformConfigSolarPulseClaim = new BooleanClaim({
    id: 'platform_configuration_solar_pulse',
    refresh: async () => {
      await this.refreshValue('platform_configuration_solar_pulse');
    },
  });

  readonly PlatformConfigWindPulseClaim = new BooleanClaim({
    id: 'platform_configuration_wind_pulse',
    refresh: async () => {
      await this.refreshValue('platform_configuration_wind_pulse');
    },
  });

  readonly PlatformConfigProjectMonitorLimitClaim =
    new PlatformConfigNumberClaim({
      id: 'platform_configuration_project_monitor_limit',
      refresh: async () => {
        await this.refreshValue('platform_configuration_project_monitor_limit');
      },
    });

  readonly PlatformConfigUserTotalReportLimitClaim =
    new PlatformConfigNumberClaim({
      id: 'platform_configuration_total_report_limit',
      refresh: async () => {
        await this.refreshValue('platform_configuration_total_report_limit');
      },
    });

  readonly PlatformConfigRequestExpertReviews = new BooleanClaim({
    id: 'platform_configuration_request_expert_reviews',
    refresh: async () => {
      await this.refreshValue('platform_configuration_request_expert_reviews');
    },
  });

  readonly PlatformConfigAllowReportDeletionClaim = new BooleanClaim({
    id: 'platform_configuration_allow_report_deletion',
    refresh: async () => {
      await this.refreshValue('platform_configuration_allow_report_deletion');
    },
  });

  readonly canDeleteReportOrProject$ = this.claimValidationResult$(
    this.PlatformConfigAllowReportDeletionClaim.validators.hasValue(true, 300),
  ).pipe(map((validationResult) => validationResult.isValid));

  readonly canAccessLocalPermits$ = this.claimValidationResult$(
    this.PlatformConfigLocalPermitsClaim.validators.hasValue(true, 300),
  ).pipe(map((validationResult) => validationResult.isValid));

  readonly canAccessSolarData$ = this.claimValidationResult$(
    this.PlatformConfigSolarPulseClaim.validators.hasValue(true, 300),
  ).pipe(map((validationResult) => validationResult.isValid));

  readonly canAccessWindData$ = this.claimValidationResult$(
    this.PlatformConfigWindPulseClaim.validators.hasValue(true, 300),
  ).pipe(map((validationResult) => validationResult.isValid));

  readonly canAccessMap$ = this.claimValidationResult$(
    this.PlatformConfigMapAccessClaim.validators.hasValue(true, 300),
  ).pipe(map((validationResult) => validationResult.isValid));

  readonly canAccessMarketplace$ = this.claimValidationResult$(
    this.PlatformConfigMarketplaceAccessClaim.validators.hasValue(true, 300),
  ).pipe(map((validationResult) => validationResult.isValid));

  readonly canAccessInterconnectionData$ = combineLatest({
    validationResult: this.claimValidationResult$(
      this.PlatformConfigInterconnectionDataTransmissionCapacityClaim.validators.isTrue(
        300,
      ),
    ),
    isNdaFormComplete: this.auth.userObserver$.pipe(
      switchMap(() =>
        this.formsService.getFormByFormTypeKey(FormTypeKey.CEII_NDA),
      ),
      map((form) => Boolean(form?.completed_on)),
      shareReplay(1),
    ),
    formTypeExempted: this.formLegalAgreementExemptionApiService
      .fetchFormTypeExemption(FormTypeKey.CEII_NDA)
      .pipe(
        map((response) => response.exempted),
        shareReplay(1),
      ),
  }).pipe(
    map(({ validationResult, isNdaFormComplete, formTypeExempted }) => {
      if (formTypeExempted) {
        return true;
      }
      return validationResult.isValid && isNdaFormComplete;
    }),
  );

  readonly canExportParcelSearch$ = this.claimValidationResult$(
    this.PlatformConfigParcelSearchExportLimitClaim.validators.hasMoreAvailable(
      300,
    ),
  ).pipe(map((validationResult) => validationResult.isValid));

  readonly hasProjectMonitor$ = this.claimValidationResult$(
    this.PlatformConfigProjectMonitorLimitClaim.validators.isEnabled(300),
  ).pipe(map((validationResult) => validationResult.isValid));

  readonly canDownloadKML$ = this.claimValidationResult$(
    this.PlatformConfigReportMapLayersExportClaim.validators.isTrue(300),
  ).pipe(map((validationResult) => validationResult.isValid));

  readonly canDownloadPDF$ = this.claimValidationResult$(
    this.PlatformConfigReportPdfExportClaim.validators.isTrue(300),
  ).pipe(map((validationResult) => validationResult.isValid));

  readonly canRequestExpertReviews$ = this.claimValidationResult$(
    this.PlatformConfigRequestExpertReviews.validators.isTrue(300),
  ).pipe(map((validationResult) => validationResult.isValid));

  readonly transectPlan$ = this.auth.userOrNull$.pipe(
    switchMap((user) => {
      if (user) {
        return this.claimValue$(this.TransectPlanClaim, 300);
      }

      return of(TransectPlanKey.Free);
    }),
  );

  readonly distributionHostingCapacityEnabled$ = this.claimValidationResult$(
    this.PlatformConfigDistributionHostingCapacityClaim.validators.hasValue(
      true,
      300,
    ),
  ).pipe(map((validationResult) => validationResult.isValid));

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private formLegalAgreementExemptionApiService: FormLegalAgreementExemptionApiService,
    private formsService: FormsApiService,
  ) {}

  public isTransectPlan$ = (key: TransectPlanKey) =>
    this.claimValidationResult$(
      this.TransectPlanClaim.validators.hasValue(key, 300),
    ).pipe(map((validationResult) => validationResult.isValid));

  private async refreshValue(id: string) {
    return firstValueFrom(
      this.http.put<void>(
        `${environment.apiUrl}/session-manager/claim/${id}/refresh`,
        {
          id,
        },
      ),
    );
  }

  /**
   * Helper method that wraps a session claim validator in an observable while conveniently passing the token payload for you
   * @example
   * // Check if user has more reports available to them this month. Claim should not be older than 10 seconds.
   * this.sessionClaimsService.claimValidationResult$(
   *  this.sessionClaimsService.PlatformConfigMonthlyReportLimitClaim.validators.hasMoreAvailable(10)
   * )
   * @param validator
   * @returns
   */
  claimValidationResult$(validator: SessionClaimValidator, userContext = {}) {
    return this.auth.userObserver$.pipe(
      switchMap(() => this.auth.getTokenPayload()),
      switchMap((payload) => {
        const shouldRefresh = validator.shouldRefresh(payload, userContext);

        return (
          shouldRefresh instanceof Promise
            ? from(shouldRefresh)
            : of(shouldRefresh)
        ).pipe(
          map((shouldRefreshResult) => ({
            shouldRefresh: shouldRefreshResult,
            payload,
          })),
        );
      }),
      switchMap(({ shouldRefresh, payload }) => {
        if (shouldRefresh) {
          return from(validator.refresh(userContext)).pipe(
            switchMap(() => this.auth.getTokenPayload()),
          );
        }

        return of(payload);
      }),
      switchMap((payload) => {
        const validationResult = validator.validate(payload, userContext);

        return validationResult instanceof Promise
          ? from(validationResult)
          : of(validationResult);
      }),
    );
  }

  claimValue$<T>(
    sessionClaim: SessionClaim<T>,
    maxAgeInSeconds?: number,
    userContext = {},
  ): Observable<T | undefined> {
    return this.auth.userObserver$.pipe(
      switchMap(() => this.auth.getTokenPayload()),
      map((payload) => {
        const lastFetchedTime = sessionClaim.getLastFetchedTime(
          payload,
          userContext,
        );

        const shouldRefresh =
          lastFetchedTime === undefined ||
          (maxAgeInSeconds !== undefined &&
            lastFetchedTime < Date.now() - maxAgeInSeconds * 1000);

        return {
          shouldRefresh,
          payload,
        };
      }),
      switchMap(({ shouldRefresh, payload }) => {
        if (shouldRefresh) {
          return from(sessionClaim.refresh(userContext)).pipe(
            switchMap(() => this.auth.getTokenPayload()),
          );
        }

        return of(payload);
      }),
      map((payload) => {
        const value = sessionClaim.getValueFromPayload(payload, userContext);

        return value;
      }),
    );
  }

  checkCanAccessSolarDataWithoutSession(userId: string | undefined | null) {
    if (!userId) {
      return of(false);
    }

    return this.fetchClaimValueWithoutSession(
      userId,
      this.PlatformConfigSolarPulseClaim.id,
    );
  }

  checkCanAccessWindDataWithoutSession(userId: string | null | undefined) {
    if (!userId) {
      return of(false);
    }

    return this.fetchClaimValueWithoutSession(
      userId,
      this.PlatformConfigWindPulseClaim.id,
    );
  }

  checkCanAccessLocalPermitsWithoutSession(userId: string | null | undefined) {
    if (!userId) {
      return of(false);
    }

    return this.fetchClaimValueWithoutSession(
      userId,
      this.PlatformConfigLocalPermitsClaim.id,
    );
  }

  fetchClaimValueWithoutSession(
    userId: string,
    claimId: string,
  ): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.apiUrl}/session-manager/users/${userId}/claims/${claimId}`,
    );
  }
}
