import { Injectable } from '@angular/core';
import { FormDTO } from '@transect-nx/data-transfer-objects';
import {
  catchError,
  distinctUntilChanged,
  map,
  NEVER,
  of,
  pipe,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {
  returnFromAction$,
  TransectStateService,
} from '../../utils/state-management';
import { AlertService } from '../../services/alert.service';
import { FormsApiService } from '../../services/backend-api/forms.service';
import { ReportAddOnApiService } from '../../services/backend-api/report-add-on-api.service';
import { FormTypeDesignation } from '@transect-nx/models';

export type State = {
  canSaveForm: boolean;
  scrolledToEnd: boolean;
  isComplete: boolean;
  completing: boolean;
  isFormValid: boolean;
  backendFormId?: string;
  formValue?: FormDTO['contents'];
  persisting: boolean;
  isFetchingPdf: boolean;
  completedOn?: Date | null;
  readOnly?: boolean;
  allowEditAfterCompletion?: boolean;
  formTypeVersionId?: string;
  addOnId?: string | null;
};

@Injectable()
export class TransectFormStateService extends TransectStateService<State> {
  private readonly completeFormAction = new Subject<void>();
  private readonly downloadPdfAction = new Subject<void>();

  private readonly destroyAction = new Subject<void>();

  private readonly catchError$ = pipe(
    catchError((error) => {
      this.updateState({
        completing: false,
        persisting: false,
        isFetchingPdf: false,
      });
      this.alertService.showError(error);
      return NEVER;
    }),
  );

  isFetchingPdf$ = this.state.pipe(
    map((state) => state.isFetchingPdf),
    distinctUntilChanged(),
  );

  completing$ = this.state.pipe(
    map((state) => state.completing),
    distinctUntilChanged(),
  );

  inProgress$ = this.state.pipe(
    map((state) => state.persisting || state.completing),
    distinctUntilChanged(),
  );

  formValue$ = this.state.pipe(
    map((state) => state.formValue),
    distinctUntilChanged(),
  );

  completedOn$ = this.state.pipe(
    map((state) => state.completedOn),
    distinctUntilChanged(),
  );

  isComplete$ = this.state.pipe(
    map((state) => state.isComplete),
    distinctUntilChanged(),
  );

  scrolledToEnd$ = this.state.pipe(
    map((state) => state.scrolledToEnd),
    distinctUntilChanged(),
  );

  canSave$ = this.state.pipe(
    map((state) => {
      return (
        state.isFormValid &&
        (state.scrolledToEnd || state.isComplete) &&
        (!state.isComplete || state.allowEditAfterCompletion) &&
        !state.persisting
      );
    }),
    distinctUntilChanged(),
  );

  saveButtonColor$ = this.canSave$.pipe(
    map<boolean | undefined, 'primary' | 'muted'>((canSave) =>
      canSave ? 'primary' : 'muted',
    ),
    distinctUntilChanged(),
  );

  completeFormEffect$ = this.completeFormAction.pipe(
    tap(() => this.updateState({ completing: true })),
    switchMap(() => {
      if (this.stateValue.backendFormId) {
        return this.formsApiService
          .updateForm(this.stateValue.backendFormId, this.stateValue.formValue)
          .pipe(this.catchError$);
      }

      return this.formsApiService
        .createForm({
          contents: this.stateValue.formValue,
          form_type_version__id: this.stateValue.formTypeVersionId,
        })
        .pipe(
          tap((form) => {
            this.updateState({ backendFormId: form._id ?? '' });
          }),
          switchMap((form) => {
            if (
              this.stateValue.addOnId &&
              form._id &&
              form.form_type?.designation ===
                FormTypeDesignation.MARKETPLACE_ITEM_FORM
            ) {
              return this.reportAddOnApiService.associateFormsWithAddOn(
                this.stateValue.addOnId,
                [form._id],
              );
            }
            return of(form);
          }),
          this.catchError$,
        );
    }),
    switchMap(() => {
      return this.formsApiService.markFormComplete(
        this.stateValue.backendFormId ?? '',
      );
    }),
    tap(() =>
      this.updateState({
        isComplete: true,
        completing: false,
      }),
    ),
    this.catchError$,
    shareReplay(1),
  );

  downloadPdfEffect$ = this.downloadPdfAction.pipe(
    tap(() => {
      this.updateState({ isFetchingPdf: true });
    }),
    switchMap(() => {
      return this.formsApiService.downloadAsPdf(
        this.stateValue.backendFormId ?? '',
      );
    }),
    tap(() => {
      this.updateState({ isFetchingPdf: false });
    }),
    this.catchError$,
    shareReplay(1),
  );

  constructor(
    private formsApiService: FormsApiService,
    private alertService: AlertService,
    private reportAddOnApiService: ReportAddOnApiService,
  ) {
    super({
      canSaveForm: false,
      scrolledToEnd: false,
      isFormValid: false,
      isComplete: false,
      completing: false,
      persisting: false,
      isFetchingPdf: false,
    });
  }

  dispatchPdfDownloadAction() {
    this.downloadPdfAction.next();
    return returnFromAction$(this.downloadPdfEffect$);
  }

  dispatchCompleteFormAction() {
    this.completeFormAction.next();
    return returnFromAction$(this.completeFormEffect$);
  }

  dispatchScrolledToEndAction() {
    this.updateState({
      scrolledToEnd: true,
    });
  }

  dispatchFormValidAction(isFormValid: boolean) {
    this.updateState({
      isFormValid,
    });
  }

  dispatchFormIsReadOnly(isReadOnly: boolean) {
    this.updateState({
      readOnly: isReadOnly,
    });
  }

  dispatchAllowEditAfterCompletion(allowEditAfterCompletion: boolean) {
    this.updateState({
      allowEditAfterCompletion: allowEditAfterCompletion,
    });
  }

  dispatchFormValueChangeAction(
    isFormValid: boolean,
    formValue: FormDTO['contents'],
    formTypeId: string,
    addOnId?: string | null,
  ) {
    this.updateState({
      formValue,
      isFormValid,
      formTypeVersionId: formTypeId,
      addOnId,
    });

    return of(null);
  }

  /**
   * this method sets the starting state of the form from the database
   * @param backendFormId the form id stored in the database
   * @param formValue the contents of the form
   * @param isComplete whether the form is complete or not
   * @param completedOn the date the form was completed
   */
  dispatchBackendFormStateAction(
    backendFormId: string,
    formValue: FormDTO['contents'],
    isComplete: boolean,
    completedOn?: Date | null,
  ) {
    this.updateState({
      backendFormId,
      formValue,
      isComplete,
      completedOn,
    });
  }

  registerEffects(): void {
    this.completeFormEffect$.pipe(takeUntil(this.destroyAction)).subscribe();
    this.downloadPdfEffect$.pipe(takeUntil(this.destroyAction)).subscribe();
  }

  unRegisterEffects(): void {
    this.destroyAction.next();
  }
}
