import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { OldGeometrySchema } from '../../utils/geometry-object-schema';
import { GeographyDTO } from '../geography/geography-dto';
export const Regulator = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema.nullable(),
  updated_at: DateSchema.nullable(),
  deleted_at: DateSchema.nullable(),
  name: z.string().nullable(),
  geometry: OldGeometrySchema.nullish(),
  preview: OldGeometrySchema.nullish(),
  bbox: OldGeometrySchema.nullish(),
  acronym: z.string().nullable(),
  profile: z
    .object({
      zip: z.preprocess(
        (val) => (typeof val === 'number' ? val.toString() : val),
        z.string().nullable(),
      ),
      city: z.string().nullable(),
      phone: z.string().nullable(),
      state: z.string().nullable(),
      twitter: z.string().nullable(),
      linkedin: z.string().nullable(),
      facebook: z.string().nullable(),
      instagram: z.string().nullable(),
      website: z.string().nullable(),
      youtube: z.string().nullable(),
      address1: z.string().nullable(),
      address2: z.string().nullable(),
      logo_url: z.string().nullable(),
      fax: z.string().nullable(),
      email: z.string().nullable(),
    })
    .deepPartial(),
  archive: z.object({}).passthrough().nullable(),
  content: z.object({}).passthrough().nullable(),
  admin: z.object({}).passthrough().nullable(),
  jurisdiction: z.string().nullable(),
  child_type: z.string().nullable(),
  order: z.number().nullable(),
  active: z.boolean().default(true),
  status_expert: z.string().nullable(),
  parent__id: z.string().uuid().nullable(),
  owner__id: z.string().uuid().nullable(),
  geography__id: z.string().uuid().nullable(),
  creator__id: z.string().uuid().nullable(),
  updater__id: z.string().uuid().nullable(),
  region: z.string().nullable(),
  geography: z.union([GeographyDTO.partial(), z.string().uuid()]).nullish(),
  regulator_path: z.array(z.string()).optional(),
  nameAcronymBack: z.string().nullable(),
});

export const RegulatorDTO = Regulator.extend({
  parent: z.union([Regulator.partial(), z.string()]).nullable(),
  children: z.any().nullish(),
});

export type RegulatorDTO = z.infer<typeof RegulatorDTO>;
