import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { OldGeometrySchema } from '../../utils/geometry-object-schema';
import { GeographyState } from '../geographies/geography-state';
import { ProjectContext } from './project.context.dto';
import { ProjectStatus, CreatedFrom } from '@transect-nx/models';

export const Preferences = z.object({
  visionColor: z.string().optional(),
});

export const ProjectDTO = z.object({
  _id: z.string().uuid(),
  type: z.string().default('standard').nullish(),
  client_identifier: z.string().nullish(),
  name: z.string().nullish(),
  description: z.string().nullish(),
  status: z.nativeEnum(ProjectStatus).nullish(),
  geometry: OldGeometrySchema.nullish(),
  context: ProjectContext.partial().optional(),
  geographies_states: z.array(GeographyState).nullish(),
  public: z.boolean().default(false),
  pinned: z.boolean().default(false),
  is_official_demo: z.boolean().default(false),
  cleanup_weekly: z.boolean().default(true),
  active: z.boolean().default(true),
  preferences: Preferences.nullish(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.nullish(),
  creator__id: z.string().uuid().nullish(),
  customer__id: z.string().uuid().nullish(),
  created_from: z.nativeEnum(CreatedFrom).default(CreatedFrom.NA),
});
export type ProjectDTO = z.infer<typeof ProjectDTO>;
