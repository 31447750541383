<a
  data-cy="link-cell"
  (click)="
    $event.stopImmediatePropagation(); params?.linkClickHandler(params?.data)
  "
  [innerHTML]="params?.value"
  *ngIf="!hidden && !disabled"
  data-cy="link-action"
></a>
<div [innerHTML]="params?.value" *ngIf="!hidden && disabled"></div>
