import { z } from 'zod';
import { FormTypeKey } from '@transect-nx/models';
import {
  FormCarouselFragmentSchema,
  FormInputFragmentSchema,
  FormMapboxImageFragmentSchema,
  FormTextFragmentSchema,
} from '../../common-schemas';

export const GetFormTypeLatestVersionParams = z.object({
  key: z.nativeEnum(FormTypeKey),
});

export type GetFormTypeLatestVersionParams = z.infer<
  typeof GetFormTypeLatestVersionParams
>;

export const GetFormTypeLatestVersionResponse = z.object({
  _id: z.string().uuid(),
  form_type: z.object({
    key: z.nativeEnum(FormTypeKey),
    name: z.string(),
  }),
  version: z.number(),
  fields: z.record(z.string()),
  model: z.object({
    title: z.string(),
    clauses: z.array(
      z.object({
        fragments: z.array(
          z.union([
            FormCarouselFragmentSchema,
            FormTextFragmentSchema,
            FormInputFragmentSchema,
            FormMapboxImageFragmentSchema,
          ]),
        ),
      }),
    ),
  }),
});

export type GetFormTypeLatestVersionResponse = z.infer<
  typeof GetFormTypeLatestVersionResponse
>;
