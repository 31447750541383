import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { OldGeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const GeologyProperties = z.object({
  url: z.string(),
  state: z.string(),
  lith62: z.string(),
  source: z.string(),
  unit_age: z.string().nullable(),
  unit_link: z.string(),
  lith62mino: z.string().nullable(),
});

export const ReportPartGeologyFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  properties: GeologyProperties.deepPartial(),
  formation: z.string().nullable(),
  description: z.string().nullable(),
  rock_type_primary: z.string().nullable(),
  rock_type_secondary: z.string().nullable(),
  unit_id: z.string().nullable(),
  geologic_age: z.string().nullable(),
  geometry: OldGeometrySchema,
  source__id: z.string().uuid(),
  transect_feature_type: z.literal('geology'),
  transect_summary: TransectSummarySchema,
});

export type ReportPartGeologyFeatureDTO = z.infer<
  typeof ReportPartGeologyFeatureDTO
>;
