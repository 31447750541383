import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { OldGeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const SoilProperties = z.object({
  mukey: z.string(),
  musym: z.string(),
  iccdcd: z.string().nullable(),
  muname: z.string(),
  niccdcd: z.string().nullable(),
  engdwbll: z.string(),
  engdwbml: z.string(),
  engsldcd: z.string(),
  engsldcp: z.string(),
  hydclprs: z.string(),
  mustatus: z.string().nullable(),
  aws025wta: z.number().nullable(),
  aws050wta: z.number().nullable(),
  engcmssmp: z.string(),
  engdwbdcd: z.string(),
  englrsdcd: z.string(),
  engstafll: z.string(),
  engstafml: z.string(),
  farmlndcl: z.string(),
  hydgrpdcd: z.string().nullable(),
  iccdcdpct: z.number(),
  areasymbol: z.string(),
  awmmfpwwta: z.number().nullable(),
  aws0100wta: z.number().nullable(),
  aws0150wta: z.number().nullable(),
  drclassdcd: z.string().nullable(),
  engcmssdcd: z.string(),
  engdwobdcd: z.string(),
  engstafdcd: z.string(),
  niccdcdpct: z.number(),
  shape_area: z.number(),
  spatialver: z.number(),
  brockdepmin: z.number().nullable(),
  brockdepminft: z.number().nullable(),
  flodfreqdcd: z.string().nullable(),
  flodfreqmax: z.string().nullable(),
  forpehrtdcp: z.string(),
  pondfreqprs: z.string(),
  urbrecptdcd: z.string(),
  urbrecptwta: z.number().nullable(),
  wtdepannmin: z.number().nullable(),
  hydricrating: z.string(),
  import_state: z.string(),
  shape_length: z.number(),
  slopegraddcp: z.number().nullable(),
  slopegradwta: z.number().nullable(),
  reference__id: z.string(),
  drclasswettest: z.string().nullable(),
  wtdepaprjunmin: z.number().nullable(),
});

export const ReportPartSoilFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  properties: SoilProperties.deepPartial(),
  musym: z.string().nullable(),
  muname: z.string().nullable(),
  mukey: z.string().nullable(),
  muaggatt: z.string().nullable(),
  mapunit: z.string().nullable(),
  hydgrpdcd: z.string().nullable(),
  farmlndcl: z.string().nullable(),
  aws0150wta: z.string().nullable(),
  geometry: OldGeometrySchema,
  source__id: z.string().uuid(),
  transect_feature_type: z.literal('soil'),
  transect_summary: TransectSummarySchema,
});

export type ReportPartSoilFeatureDTO = z.infer<typeof ReportPartSoilFeatureDTO>;
