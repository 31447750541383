import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { CustomMapLayersUploadAccess } from '@transect-nx/models';

export const UpdateCustomerBodyDTO = z.object({
  billing_notes: z.string().nullable().optional(),
  chargify_customer_id: z.number().nullable().optional(),
  domain: z.string().nullish(),
  industry__id: z.string().uuid().optional().nullable(),
  name: z.string(),
  hubspot_customer_id: z.string().nullable(),
  subscription_active: z.boolean().optional(),
  vision_plan__id: z.string().uuid().optional(),
  terms_accepted: z.boolean().optional(),
  subscription_start_date: DateSchema.nullable().optional(),
  subscription_end_date: DateSchema.nullable().optional(),
  pilot_start_date: DateSchema.nullable().optional(),
  pilot_end_date: DateSchema.nullable().optional(),
  pilot_states: z.array(z.string()).optional(),
  shared_portfolio: z.boolean().optional(),
  shared_site_selections: z.boolean().optional(),
  all_collaborator: z.boolean().optional(),
  payment_overdue: z.boolean().optional(),
  logo_gcs: z.object({}).passthrough().nullish(),
  marketplace_discount_pct: z.number().default(0),
  subscription_custom_map_layers_upload: z
    .nativeEnum(CustomMapLayersUploadAccess)
    .default(CustomMapLayersUploadAccess.OFF)
    .optional(),
  free_trial_expiration_date: DateSchema.nullish(),
});

export type UpdateCustomerBodyDTO = z.infer<typeof UpdateCustomerBodyDTO>;
