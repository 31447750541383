import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { FormTypeKey } from '@transect-nx/models';
import {
  FormCarouselFragmentSchema,
  FormInputFragmentSchema,
  FormMapboxImageFragmentSchema,
  FormTextFragmentSchema,
} from '../../common-schemas';
import { FormDTO } from '../../models';

// getMyForms BEGIN

export const GetMyFormsQueryParams = z.object({
  page: z
    .preprocess((val) => {
      if (typeof val === 'string') {
        return Number(val);
      }

      return val;
    }, z.number())
    .default(1),
  pageSize: z
    .preprocess((val) => {
      if (typeof val === 'string') {
        return Number(val);
      }

      return val;
    }, z.number())
    .default(25),
  search: z.string().optional(),
});

export type GetMyFormsQueryParams = z.infer<typeof GetMyFormsQueryParams>;

export const GetMyFormsResponse = z.array(
  z.object({
    _id: z.string().uuid().optional(),
    form_type__id: z.string().uuid(),
    creator__id: z.string().uuid(),
    completed_by__id: z.string().uuid().nullish().optional(),
    contents: z
      .record(
        z.string(),
        z.union([z.string(), z.number(), z.boolean(), DateSchema]).nullish(),
      )
      .optional(),
    form_type: z.object({
      _id: z.string().uuid(),
      key: z.nativeEnum(FormTypeKey),
      name: z.string(),
    }),
    completed_on: DateSchema.nullable(),
    created_at: DateSchema.optional(),
  }),
);

export type GetMyFormsResponse = z.infer<typeof GetMyFormsResponse>;

// getMyForms END

export const GetFormFormTypeVersionParams = z.object({
  id: z.string().uuid(),
});

export type GetFormFormTypeVersionParams = z.infer<
  typeof GetFormFormTypeVersionParams
>;

export const GetFormFormTypeVersionResponse = z.object({
  _id: z.string().uuid(),
  form_type: z.object({
    key: z.nativeEnum(FormTypeKey),
    name: z.string(),
  }),
  version: z.number(),
  fields: z.record(z.string()),
  model: z.object({
    title: z.string(),
    clauses: z.array(
      z.object({
        fragments: z.array(
          z.union([
            FormTextFragmentSchema,
            FormInputFragmentSchema,
            FormMapboxImageFragmentSchema,
            FormCarouselFragmentSchema,
          ]),
        ),
      }),
    ),
  }),
});

export type GetFormFormTypeVersionResponse = z.infer<
  typeof GetFormFormTypeVersionResponse
>;

export const GetFormByFormTypeKeyParams = z.object({
  formTypeKey: z.nativeEnum(FormTypeKey),
});

export type GetFormByFormTypeKeyParams = z.infer<
  typeof GetFormByFormTypeKeyParams
>;

export const GetFormByFormTypeKeyQuery = z.object({
  addOnId: z.string().uuid().optional(),
  userId: z.string().uuid().optional(),
});

export type GetFormByFormTypeKeyQuery = z.infer<
  typeof GetFormByFormTypeKeyQuery
>;

export const GetFormByFormTypeKeyResponse = FormDTO;

export type GetFormByFormTypeKeyResponse = z.infer<
  typeof GetFormByFormTypeKeyResponse
>;

export const GetFormByIdParams = z.object({
  id: z.string().uuid(),
});

export type GetFormByIdParams = z.infer<typeof GetFormByIdParams>;

export const GetFormByIdResponse = FormDTO;

export type GetFormByIdResponse = z.infer<typeof GetFormByIdResponse>;
