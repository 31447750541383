import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { OldGeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const ContourProperties = z.object({
  fcode: z.number(),
  cell_id: z.number(),
  ogc_fid: z.number(),
  globalid: z.string(),
  loaddate: z.string(),
  contourunits: z.number(),
  shape_length: z.number(),
  data_security: z.number(),
  nedresolution: z.number(),
  reference__id: z.string(),
  contourinterval: z.number(),
  source_datadesc: z.string(),
  contourelevation: z.number(),
  source_datasetid: z.string(),
  source_featureid: z.string().nullable(),
  source_originator: z.string(),
  distribution_policy: z.string(),
  permanent_identifier: z.string(),
});

export const ReportPartContourFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  properties: ContourProperties.deepPartial(),
  elevation: z.string().nullable(),
  geometry: OldGeometrySchema,
  source__id: z.string().uuid(),
  transect_feature_type: z.literal('contour'),
  transect_summary: TransectSummarySchema,
});

export type ReportPartContourFeatureDTO = z.infer<
  typeof ReportPartContourFeatureDTO
>;
