import { Injectable } from '@angular/core';
import { MapService } from './map.service';
import * as turf from '@turf/turf';
import { Observable, of, tap } from 'rxjs';

@Injectable()
export class HighlightParcelService {
  highlightedParcelMapLayerId = `highlighted-parcel-map-layer-${HighlightParcelService.name}`;
  constructor(private mapService: MapService) {}

  highlightAndCenterParcelOnMap(
    parcelFeature: turf.Feature,
  ): Observable<turf.Feature> {
    return of(parcelFeature).pipe(
      tap((feature: turf.Feature) => {
        this.mapService.centerOnFeatures([feature]);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.highlightParcelOnMap(feature);
      }),
    );
  }

  private highlightParcelOnMap(parcelFeature: turf.Feature) {
    this.removeHighlightedParcelLayer();

    const transectMap = this.mapService.getMap();
    const featureCollection = turf.helpers.featureCollection([parcelFeature]);

    transectMap?.addSource(this.highlightedParcelMapLayerId, {
      type: 'geojson',
      data: featureCollection as GeoJSON.FeatureCollection,
    });

    transectMap?.addLayer({
      id: this.highlightedParcelMapLayerId,
      type: 'fill',
      source: this.highlightedParcelMapLayerId,
      filter: ['==', '$type', 'Polygon'],
      paint: {
        'fill-color': 'rgb(52, 104, 108)',
        'fill-outline-color': 'rgb(0, 61, 65)',
        'fill-opacity': 0.7,
      },
    });
  }

  removeHighlightedParcelLayer() {
    const transectMap = this.mapService.getMap();
    if (transectMap?.getLayer(this.highlightedParcelMapLayerId)) {
      transectMap.removeLayer(this.highlightedParcelMapLayerId);
    }
    if (transectMap?.getSource(this.highlightedParcelMapLayerId)) {
      transectMap.removeSource(this.highlightedParcelMapLayerId);
    }
  }
}
