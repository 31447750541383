import { timer } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostBinding,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'ts-map-control-window',
  templateUrl: './map-control-window.component.html',
  styleUrls: ['./map-control-window.component.scss'],
})
export class MapControlWindowComponent implements OnInit, AfterViewInit {
  @ViewChild('headerActions') headerActions?: ElementRef<HTMLDivElement>;

  @Input() isDynamicHeight = false;
  @Input() isDraggable = false;

  @Input() showWarning = false;

  /**
   * The window's fixed width in px.
   */
  @Input() width = 300;

  /**
   * The window's fixed height in px.
   */
  @Input() height?: number = 300;

  /**
   * The window's header label.
   */
  @Input() header?: string;

  /**
   * The window's starting coordinates in px
   */
  @Input() position: {
    top?: number | string;
    left?: number | string;
    right?: number | string;
    bottom?: number | string;
  } = { top: 0, left: 0 };

  @Input() isPositionAbsolute = true;

  @Input() headerAlign: 'left' | 'center' | 'right' = 'left';

  @Input() shouldDisplayToggleBtn = true;
  @Input() isSmallBorderRadius = false;
  @Input() isContentExpanded = true;
  @Output() windowStateChange = new EventEmitter<boolean>();

  minimized = false;
  headerActionsWidth = 0;

  @HostBinding('class.accordion-expanded-dynamic-height')
  get dynamicHeightAccordionExpanded(): boolean {
    return !this.minimized && this.isDynamicHeight;
  }

  @HostBinding('class.accordion-expanded-fixed-height')
  get fixedHeightAccordionExpanded(): boolean {
    return !this.minimized && !this.isDynamicHeight;
  }

  ngOnInit(): void {
    if (this.isDraggable && this.isDynamicHeight) {
      console.warn('Height cannot be dynamic if the window is draggable.');
      this.isDynamicHeight = false;
    }

    for (const [key, val] of Object.entries(this.position)) {
      const stringValue = typeof val === 'number' ? `${val}px` : val;

      this.position[key] = stringValue;
    }
  }

  ngAfterViewInit(): void {
    timer(0).subscribe(() => {
      if (this.header) {
        this.headerActionsWidth =
          this.headerActions?.nativeElement.getBoundingClientRect().width ?? 0;
      }
    });
  }

  toggleWindow(): void {
    this.minimized = !this.minimized;
    this.windowStateChange.emit(this.minimized);
  }
}
