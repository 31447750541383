import { z } from 'zod';
import {
  pageNumber,
  pageSize,
  sortModel,
} from '../../utils/paginated-response-rows';
import { ResponseRows } from '../../utils/response-rows';
import { LandownerContactOrderStatusEnum } from '@transect-nx/models';

export enum LandownerContactOrderType {
  ALL_IN_AOI = 'all_in_aoi',
  SELECTED = 'selected',
}

export enum LandownerParcelSourceEntity {
  PARCEL_FILTER = 'parcel-filter',
  PROJECT = 'project',
  REPORT_PART = 'report-part',
}

const SelectedLandownerContactOrderPayload = z.object({
  orderType: z.literal(LandownerContactOrderType.SELECTED),
  parcelIds: z.array(z.string().uuid()).min(1),
  parcelUniqueIdentifiers: z.array(z.string().uuid()).min(1),
  entityId: z.string().uuid().optional(),
  entityType: z.nativeEnum(LandownerParcelSourceEntity).optional(),
  reportVersion: z.string().optional(),
});

const AllInAOILandownerContactOrderPayload = z.object({
  orderType: z.literal(LandownerContactOrderType.ALL_IN_AOI),
  entityId: z.string().uuid(),
  entityType: z.nativeEnum(LandownerParcelSourceEntity),
  parcelIds: z.array(z.string().uuid()).optional(),
  parcelUniqueIdentifiers: z.array(z.string().uuid()).optional(),
  reportVersion: z.string().optional(),
});

export const CreateLandownerContactOrderPayload = z
  .discriminatedUnion('orderType', [
    SelectedLandownerContactOrderPayload,
    AllInAOILandownerContactOrderPayload,
  ])
  .refine(
    (data) => {
      if (
        data.orderType === LandownerContactOrderType.ALL_IN_AOI &&
        data.entityType === LandownerParcelSourceEntity.REPORT_PART
      ) {
        return !!data.reportVersion;
      }
      return true;
    },
    {
      message: 'Report Version is required to order for all report parcels',
      path: ['reportVersion'],
    },
  )
  .refine(
    (data) => {
      if (data.orderType === LandownerContactOrderType.SELECTED) {
        return data.parcelIds && data.parcelIds.length > 0;
      }
      return true;
    },
    {
      message:
        'Parcel IDs must be provided and non-empty for order type "SELECTED".',
      path: ['parcelIds'],
    },
  )
  .refine(
    (data) => {
      if (data.orderType === LandownerContactOrderType.ALL_IN_AOI) {
        return data.entityId && data.entityType;
      }
      return true;
    },
    {
      message:
        'Both entityId and entityType are required for order type "ALL_IN_AOI".',
      path: ['entityId', 'entityType'],
    },
  );

export type CreateLandownerContactOrderPayload = z.infer<
  typeof CreateLandownerContactOrderPayload
>;

export const LandownerContactOrderDTO = z.object({
  _id: z.string().uuid(),
  customer__id: z.string().uuid(),
  user__id: z.string().uuid(),
  parcel_ids: z.array(z.string()).nonempty(),
  missing_parcel_ids: z.array(z.string()).optional().nullable(),
  status: z.nativeEnum(LandownerContactOrderStatusEnum),
  total_parcels: z.number(),
  received_parcels: z.number().optional(),
});

export const FetchMyLandownerContactOrderRowsDTO = ResponseRows(
  z.object({
    order__id: z.string().uuid(),
    created_at: z.date(),
    requested_by: z.string(),
    credits: z.number(),
    status: z.nativeEnum(LandownerContactOrderStatusEnum),
  }),
);

export const FetchMyLandownerContactOrdersQuery = z.object({
  page: pageNumber,
  pageSize: pageSize.default('50'),
  sortModel: sortModel.nullish(),
});

export type FetchMyLandownerContactOrdersQuery = z.infer<
  typeof FetchMyLandownerContactOrdersQuery
>;
export type LandownerContactOrderDTO = z.infer<typeof LandownerContactOrderDTO>;

export const LandownerContactUnmatchedRecord = z.object({
  parcel_number: z.string().nullish(),
  owner_name: z.string().nullish(),
});

export type LandownerContactUnmatchedRecord = z.infer<
  typeof LandownerContactUnmatchedRecord
>;

export const GetUnMatchedRecordsInOrderDTO = z.array(
  LandownerContactUnmatchedRecord,
);

export type GetUnMatchedRecordsInOrderDTO = z.infer<
  typeof GetUnMatchedRecordsInOrderDTO
>;

export type FetchMyLandownerContactOrderRowsDTO = z.infer<
  typeof FetchMyLandownerContactOrderRowsDTO
>;

export const GetUnMatchedRecordsQuery = z.object({
  page: pageNumber.default('1'),
  pageSize: pageSize.default('50'),
});

export type GetUnMatchedRecordsQuery = z.infer<typeof GetUnMatchedRecordsQuery>;
