import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subject, take, takeUntil } from 'rxjs';
import { ZodError } from 'zod';
import { TransectAPIError } from '../models/transect-api-error';
import {
  SnackbarAlertComponent,
  SnackbarAlertType,
} from '../shared/components/snackbar-alert/snackbar-alert.component';

export enum SnackbarActions {
  REPORT_MAP_SAVED = 'report-map-saved',
}

type Options = {
  duration?: number | 'infinite';
  dismissible?: boolean;
  withTitle?: boolean;
  actionLinkText?: string;
  actionType?: SnackbarActions;
  title?: string;
};

@Injectable({
  providedIn: 'root',
})
export class AlertService implements OnDestroy {
  private snackbarActionsSubject = new Subject<
    SnackbarActions | undefined | null
  >();
  snackbarActions$ = this.snackbarActionsSubject.asObservable();

  private destroy$ = new Subject<void>();

  constructor(private snackbar: MatSnackBar) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showSuccess(message: string, options?: Options) {
    const data = {
      message,
      type: options?.actionType,
      actionLinkText: options?.actionLinkText,
      alertType: SnackbarAlertType.SUCCESS,
      dismissible: options?.dismissible,
      onDismiss: () => this.snackbar.dismiss(),
      title: '',
    };

    if (options?.withTitle) {
      data.title = options.title || 'Success!';
    }

    this.snackbar
      .openFromComponent(SnackbarAlertComponent, {
        duration:
          options?.duration === 'infinite'
            ? undefined
            : options?.duration ?? 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: ['success-alert'],
        data,
      })
      .onAction()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => this.snackbarActionsSubject.next(options?.actionType));
  }

  showError<
    T extends
      | string
      | TransectAPIError
      | ZodError
      | Error
      | HttpErrorResponse
      | { error?: unknown; message?: unknown }
      | undefined
      | null
      | unknown,
  >(error?: T, options?: Options): void {
    const data = {
      message: this.readErrorMessage(error),
      alertType: SnackbarAlertType.ERROR,
      onDismiss: () => this.snackbar.dismiss(),
      dismissible: true,
      title: '',
    };

    if (options?.withTitle) {
      data.title = options.title || this.readErrorTitle(error);
    }

    // Duration as function of message length. min = 5sec, max = 60sec
    const duration = Math.min(Math.max(5000, data.message.length * 100), 60000);

    this.snackbar.openFromComponent(SnackbarAlertComponent, {
      duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['error-alert'],
      data,
    });
  }

  showWarn<
    T extends
      | string
      | TransectAPIError
      | ZodError
      | HttpErrorResponse
      | Error
      | { error?: unknown; message?: unknown }
      | { title?: string; description?: string },
  >(error: T, options?: Options): void {
    const data = {
      message: this.readErrorMessage(error),
      alertType: SnackbarAlertType.WARN,
      dismissible: options?.dismissible,
      onDismiss: () => this.snackbar.dismiss(),
      title: '',
    };

    if (options?.withTitle) {
      data.title = options.title || this.readErrorTitle(error);
    }

    // Duration as function of message length. min = 5sec, max = 60sec
    const duration =
      options?.duration === 'infinite'
        ? undefined
        : (options?.duration
            ? options.duration
            : Math.min(Math.max(5000, data.message.length * 100)),
          60000);

    this.snackbar.openFromComponent(SnackbarAlertComponent, {
      duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: ['warn-alert'],
      data,
    });
  }

  readErrorTitle<
    T extends
      | string
      | TransectAPIError
      | ZodError
      | Error
      | { error?: unknown; message?: unknown }
      | { title?: string; description?: string }
      | undefined
      | unknown
      | null,
  >(error: T): string {
    const defaultTitle = 'Error!';

    if (typeof error === 'string') {
      return defaultTitle;
    } else if (error instanceof TransectAPIError && error.title) {
      return error.title;
    } else if (error instanceof ZodError) {
      console.error(error.issues);
    } else if (error instanceof Error) {
      return error.name;
    } else if (
      error &&
      typeof error === 'object' &&
      'title' in error &&
      typeof error.title === 'string'
    ) {
      return error.title ?? defaultTitle;
    } else if (
      error &&
      typeof error === 'object' &&
      'error' in error &&
      typeof error?.error === 'string'
    ) {
      return defaultTitle;
    } else if (
      error &&
      typeof error === 'object' &&
      'message' in error &&
      typeof error?.message === 'string'
    ) {
      return defaultTitle;
    }

    return defaultTitle;
  }

  readErrorMessage<
    T extends
      | string
      | TransectAPIError
      | ZodError
      | Error
      | HttpErrorResponse
      | { error?: unknown; message?: unknown }
      | { title?: string; description?: string }
      | undefined
      | unknown
      | null,
  >(error: T) {
    let message =
      'An unexpected error occurred. Please try again. If the problem persists, please contact customer support.';

    if (error === undefined || error === null) {
      return message;
    }

    if (typeof error === 'string') {
      message = error;
    } else if (error instanceof TransectAPIError) {
      message = error.detail ?? message;
    } else if (error instanceof HttpErrorResponse) {
      message = TransectAPIError.fromHttpErrorResponse(error).detail ?? message;
    } else if (error instanceof ZodError) {
      console.error(error.issues);
    } else if (error instanceof Error) {
      message = error.message ?? message;
    } else if (
      error &&
      typeof error === 'object' &&
      'description' in error &&
      typeof error?.description === 'string'
    ) {
      message = error?.description ?? message;
    } else if (
      error &&
      typeof error === 'object' &&
      'error' in error &&
      typeof error?.error === 'string'
    ) {
      message = error.error ?? message;
    } else if (
      error &&
      typeof error === 'object' &&
      'message' in error &&
      typeof error?.message === 'string'
    ) {
      message = error.message ?? message;
    } else if (
      error &&
      typeof error === 'object' &&
      'detail' in error &&
      typeof error?.detail === 'string'
    ) {
      message = error.detail ?? message;
    }

    return message;
  }
}
