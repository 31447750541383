import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { take } from 'rxjs';
import { FormModalComponent } from '../modules/form-modal/form-modal.component';
import { FormModalInputData } from '../models/form-modal.service';
import { map } from 'rxjs/operators';

export type FormModalResult =
  | { formId: string | undefined; isComplete: true }
  | { isComplete: false };

@Injectable({
  providedIn: 'root',
})
export class FormModalService {
  constructor(private dialog: MatDialog) {}

  open(
    data: FormModalInputData,
    options?: {
      width?: string;
      height?: string;
      minHeight?: string;
      minWidth?: string;
    },
  ) {
    const ref = this.dialog.open<
      FormModalComponent,
      FormModalInputData,
      FormModalResult | null | undefined
    >(FormModalComponent, {
      width: options?.width ?? '500px',
      height: options?.height ?? '90vh',
      minHeight: options?.minHeight,
      minWidth: options?.minWidth,
      panelClass: 'form-modal',
      disableClose: true,
      data,
    });
    return ref.afterClosed().pipe(
      take(1),
      map((result) => result ?? ({ isComplete: false } as FormModalResult)),
    );
  }
}
