import { z } from 'zod';

const Position = z.array(z.number());

export const PointGeometrySchema = z.object({
  type: z.literal('Point'),
  coordinates: Position,
});
export type PointGeometrySchema = z.infer<typeof PointGeometrySchema>;

export const MultiPointGeometrySchema = z.object({
  type: z.literal('MultiPoint'),
  coordinates: z.array(Position),
});
export type MultiPointGeometrySchema = z.infer<typeof MultiPointGeometrySchema>;

export const LineStringGeometrySchema = z.object({
  type: z.literal('LineString'),
  coordinates: z.array(Position),
});
export type LineStringGeometrySchema = z.infer<typeof LineStringGeometrySchema>;

export const MultiLineStringGeometrySchema = z.object({
  type: z.literal('MultiLineString'),
  coordinates: z.array(z.array(Position)),
});
export type MultiLineStringGeometrySchema = z.infer<
  typeof MultiLineStringGeometrySchema
>;

export const PolygonGeometrySchema = z.object({
  type: z.literal('Polygon'),
  coordinates: z.array(z.array(Position)),
});
export type PolygonGeometrySchema = z.infer<typeof PolygonGeometrySchema>;

export const MultiPolygonGeometrySchema = z.object({
  type: z.literal('MultiPolygon'),
  coordinates: z.array(z.array(z.array(Position))),
});
export type MultiPolygonGeometrySchema = z.infer<
  typeof MultiPolygonGeometrySchema
>;

export const CoordinatesSchema = z.union([
  Position,
  z.array(Position),
  z.array(z.array(Position)),
  z.array(z.array(z.array(Position))),
]);

export type CoordinatesSchema = z.infer<typeof CoordinatesSchema>;

/**
 * @deprecated - Use {@link GeometrySchema} for Geometries or {@link GeometriesOrCollectionSchema} if you want to include GeometryCollection instead
 */
export const OldGeometrySchema = z
  .object({
    type: z.enum([
      'Point',
      'LineString',
      'Polygon',
      'MultiPoint',
      'MultiLineString',
      'MultiPolygon',
      'GeometryCollection',
    ]),
    coordinates: CoordinatesSchema.nullish(),
  })
  .passthrough();

/**
 * @deprecated - Use {@link GeometrySchema} for Geometries or {@link GeometriesOrCollectionSchema} if you want to include GeometryCollection instead
 */
export type OldGeometrySchema = z.infer<typeof OldGeometrySchema>;

export const GeometrySchema = z.union([
  PointGeometrySchema,
  MultiPointGeometrySchema,
  PolygonGeometrySchema,
  MultiPolygonGeometrySchema,
  LineStringGeometrySchema,
  MultiLineStringGeometrySchema,
]);

export type GeometrySchema = z.infer<typeof GeometrySchema>;

export const GeometryCollectionSchema = z.object({
  type: z.literal('GeometryCollection'),
  geometries: z.array(GeometrySchema),
});

export type GeometryCollectionSchema = z.infer<typeof GeometryCollectionSchema>;

export const GeometriesOrCollectionSchema = GeometrySchema.or(
  GeometryCollectionSchema,
);

export type GeometriesOrCollectionSchema = z.infer<
  typeof GeometriesOrCollectionSchema
>;

export const SafeGeometrySchema = z.preprocess(
  (val) => (typeof val === 'string' ? JSON.parse(val) : val),
  OldGeometrySchema,
);
export type SafeGeometrySchema = z.infer<typeof SafeGeometrySchema>;

export const FeatureCollectionSchema = z.object({
  type: z.literal('FeatureCollection'),
  features: z.array(
    z.object({
      id: z.union([z.string(), z.number()]).optional(),
      type: z.literal('Feature'),
      geometry: OldGeometrySchema,
      properties: z.any().optional(),
    }),
  ),
  properties: z.any().optional(),
});

export type FeatureCollectionSchema = z.infer<typeof FeatureCollectionSchema>;
