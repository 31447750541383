import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapControlWindowHeight',
})
export class MapControlWindowHeightPipe implements PipeTransform {
  transform(
    isContentExpanded: boolean = false,
    isDynamicHeight: boolean = false,
    minimized: boolean = false,
  ): string {
    if (!isContentExpanded) {
      return '92px';
    } else if (isDynamicHeight && !minimized) {
      return '100%';
    } else {
      return 'fit-content';
    }
  }
}
