import { z } from 'zod';
import { RegulationDTO } from '../regulation-dto';
import { OldGeometrySchema } from '../../../utils/geometry-object-schema';

export const RegulationShowDTO = RegulationDTO.omit({
  regulation_locations: true,
})
  .deepPartial()
  .extend({
    preview: OldGeometrySchema.nullable(),
    regulation_location_count: z.number().nullable(),
    regulation_parents: z.array(z.string().uuid()).nullable(),
    regulation_children: z.array(z.string().uuid()).nullable(),
  });

export type RegulationShowDTO = z.infer<typeof RegulationShowDTO>;
