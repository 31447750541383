import { z } from 'zod';

export const GeographyState = z.object({
  abbreviation: z.string(),
  state: z.string(),
  geography__id: z.string().uuid(),
  counties: z.string().array().nullish(),
});

export type GeographyState = z.infer<typeof GeographyState>;
