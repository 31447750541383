<div
  class="mapboxgl-ctrl map-layer-select-container"
  [ngStyle]="absolutePosition"
  [class.map-layer-absolute-position]="!!absolutePosition"
>
  <mat-form-field floatLabel="never">
    <mat-select
      [value]="baseLayer$?.getValue()"
      (selectionChange)="onBaseLayerSelectionChange($event)"
    >
      <mat-option *ngFor="let layer of baseLayers" [value]="layer.value">
        {{ layer.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<ng-container *ngIf="isReportMap">
  <ts-map-control-accordion [width]="211.88" class="legend-panel-container">
    <ng-container *ngIf="baseLayerId$ | async as baseLayerId">
      <ng-container
        *ngIf="
          baseLayerId === 'aspect-map-3dep' || baseLayerId === 'slope-map-3dep'
        "
      >
        <ts-map-control-window
          [isPositionAbsolute]="false"
          [isDynamicHeight]="true"
          [width]="211.88"
          [isSmallBorderRadius]="true"
        >
          <mat-expansion-panel
            [expanded]="isBaseLayerPanelOpen"
            (opened)="isBaseLayerPanelOpen = true"
            (closed)="isBaseLayerPanelOpen = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Legend</mat-panel-title>
            </mat-expansion-panel-header>

            <ng-container
              *ngIf="
                isBaseLayerPanelOpen &&
                ((currentLegendItems$ | async)?.length ?? 0) > 0
              "
            >
              <div class="legend-content">
                <div
                  class="legend-item"
                  *ngFor="let item of currentLegendItems$ | async"
                >
                  <span
                    class="color-box"
                    [style.backgroundColor]="item.color"
                  ></span>
                  <span class="body-small text-shades-gray-dark">
                    {{ item.label }}
                  </span>
                </div>
              </div>
            </ng-container>
          </mat-expansion-panel>
        </ts-map-control-window>
      </ng-container>
    </ng-container>
  </ts-map-control-accordion>
</ng-container>
