import { z } from 'zod';

export const FormTextFragmentSchema = z.object({
  type: z.literal('text'),
  text: z.string(),
  color: z.string().optional(),
  fontWeight: z.enum(['bold', 'normal', 'regular']).optional(),
  shift: z.number().optional(),
});

export const FormInputFragmentSchema = z.object({
  type: z.union([z.literal('input'), z.literal('checkbox')]),
  label: z.string(),
  name: z.string(),
  shift: z.number().optional(),
  optional: z.boolean().nullish(),
});

export const FormMapboxImageFragmentSchema = z.object({
  type: z.literal('mapbox-image'),
  key: z.literal('project-geometry'),
  imageBuffer: z.any().optional(),
  width: z.number().optional(),
  height: z.number().optional(),
  shift: z.number().optional(),
});

export const FormCarouselFragmentSchema = z.object({
  type: z.literal('carousel'),
  name: z.string(),
  value: z.number(),
  clauses: z.array(
    z.object({
      fragments: z.array(FormTextFragmentSchema),
    }),
  ),
});
