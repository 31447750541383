import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Style } from 'mapbox-gl';
import Rollbar, { LogArgument } from 'rollbar';
import { BehaviorSubject, NEVER, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  delay,
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AlertService } from '../../../../services/alert.service';
import { EventStreamService } from '../../../../services/event-stream.service';
import { RollbarService } from '../../../../services/rollbar.service';
import { TransectMap } from '../../classes/TransectMap';

export interface BaseLayerOption {
  id?: string;
  label: string;
  value: string | Style;
}

@Component({
  selector: 'ts-base-layer-control',
  templateUrl: './base-layer-control.component.html',
  styleUrls: ['./base-layer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseLayerControlComponent implements OnInit, OnDestroy {
  isBaseLayerPanelOpen = false;

  transectMap$ = new BehaviorSubject<TransectMap | null>(null);
  absolutePosition?: {
    top?: number | string;
    left?: number | string;
    right?: number | string;
    bottom?: number | string;
  };
  isReportMap?: boolean;

  baseLayer$?: BehaviorSubject<string | Style>;
  baseLayerId$?: Observable<string>;

  SATELLITE_STREETS_3D =
    'mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y';

  baseLayers: BaseLayerOption[] = [
    {
      label: 'Streets',
      value: 'mapbox://sprites/mapbox/streets-v11',
    },
    {
      label: 'Satellite',
      value: 'mapbox://sprites/mapbox/satellite-v9',
    },
    {
      label: 'Satellite Streets',
      value: 'mapbox://sprites/mapbox/satellite-streets-v11',
    },
    {
      label: 'Light',
      value: 'mapbox://sprites/mapbox/light-v10',
    },
    {
      label: 'Dark',
      value: 'mapbox://sprites/mapbox/dark-v10',
    },
    {
      label: 'Outdoors',
      value: 'mapbox://sprites/mapbox/outdoors-v11',
    },
    {
      label: 'USGS Imagery Topo',
      value: {
        version: 8,
        glyphs: 'mapbox://fonts/openmaptiles/{fontstack}/{range}.pbf',
        layers: [
          {
            id: '28ba71d4-19cb-427e-9b81-1779e154c777-layer1',
            type: 'raster',
            source: '28ba71d4-19cb-427e-9b81-1779e154c777-source1',
            maxzoom: 20,
            minzoom: 0,
          },
        ],
        sources: {
          '28ba71d4-19cb-427e-9b81-1779e154c777-source1': {
            type: 'raster',
            maxzoom: 15,
            tileSize: 256,
            tiles: [
              '//server.arcgisonline.com/arcgis/rest/services/USA_Topo_Maps/MapServer/tile/{z}/{y}/{x}',
            ],
          },
        },
      },
    },
    {
      label: 'USGS NAIP Imagery',
      value: {
        version: 8,
        glyphs: 'mapbox://fonts/openmaptiles/{fontstack}/{range}.pbf',
        layers: [
          {
            id: '49ddcf50-6b1a-4fcf-986b-6c7d6b056ef5-layer1',
            type: 'raster',
            source: '49ddcf50-6b1a-4fcf-986b-6c7d6b056ef5-source1',
            maxzoom: 18,
            minzoom: 10,
          },
        ],
        sources: {
          '49ddcf50-6b1a-4fcf-986b-6c7d6b056ef5-source1': {
            type: 'raster',
            maxzoom: 18,
            tileSize: 256,
            tiles: [
              '//imagery.nationalmap.gov/arcgis/rest/services/USGSNAIPPlus/ImageServer/exportImage?f=image&bbox={bbox-epsg-3857}&imageSR=3857&bboxSR=3857&size=256%2C256',
            ],
          },
        },
      },
    },
    {
      id: 'slope-map-3dep',
      label: 'Slope Map from USGS 3D Elevation Program (3DEP)',
      value: {
        version: 8,
        glyphs: 'mapbox://fonts/openmaptiles/{fontstack}/{range}.pbf',
        layers: [
          {
            id: '6ce9b5f9-a53e-4131-8aca-15338f1ebe48-layer1',
            type: 'raster',
            source: '6ce9b5f9-a53e-4131-8aca-15338f1ebe48-source1',
            maxzoom: 18,
            minzoom: 10,
          },
        ],
        sources: {
          '6ce9b5f9-a53e-4131-8aca-15338f1ebe48-source1': {
            type: 'raster',
            maxzoom: 18,
            tileSize: 256,
            tiles: [
              '//elevation.nationalmap.gov/arcgis/rest/services/3DEPElevation/ImageServer/exportImage?f=image&bbox={bbox-epsg-3857}&imageSR=3857&bboxSR=3857&size=256%2C256&&renderingRule=%7B"rasterFunction"%3A"Slope+Map"%7D',
            ],
          },
        },
      },
    },
    {
      id: 'aspect-map-3dep',
      label: 'Aspect Map from USGS 3D Elevation Program (3DEP)',
      value: {
        version: 8,
        glyphs: 'mapbox://fonts/openmaptiles/{fontstack}/{range}.pbf',
        layers: [
          {
            id: '2a1a03d3-99c1-45e0-9854-7869a8c62d0c-layer1',
            type: 'raster',
            source: '2a1a03d3-99c1-45e0-9854-7869a8c62d0c-source1',
            maxzoom: 18,
            minzoom: 10,
          },
        ],
        sources: {
          '2a1a03d3-99c1-45e0-9854-7869a8c62d0c-source1': {
            type: 'raster',
            tiles: [
              '//elevation.nationalmap.gov/arcgis/rest/services/3DEPElevation/ImageServer/exportImage?f=image&bbox={bbox-epsg-3857}&imageSR=3857&bboxSR=3857&size=256%2C256&&renderingRule=%7B"rasterFunction"%3A"Aspect+Map"%7D',
            ],
            maxzoom: 18,
            tileSize: 256,
          },
        },
      },
    },
    {
      label: 'Satellite Streets 3D',
      value: this.SATELLITE_STREETS_3D,
    },
  ];

  private isControlReady$ = new BehaviorSubject(false);
  public waitForControlReady$ = this.isControlReady$.pipe(
    first((isReady) => isReady),
    map(() => {
      /* Intentionally left blank */
    }),
  );

  currentLegendItems$?: Observable<{ label: string; color: string }[]>;

  private legendItems: { [key: string]: { label: string; color: string }[] } = {
    'aspect-map-3dep': [
      { label: '0 - North', color: '#8bc34a' },
      { label: '45 - Northeast', color: '#4caf50' },
      { label: '90 - East', color: '#2196f3' },
      { label: '135 - Southeast', color: '#9c27b0' },
      { label: '180 - South', color: '#f44336' },
      { label: '225 - Southwest', color: '#ff5722' },
      { label: '270 - West', color: '#ff9800' },
      { label: '315 - Northwest', color: '#ffeb3b' },
    ],
    'slope-map-3dep': [
      { label: '<5 Degrees Slope', color: '#e0d9e7' },
      { label: '5-10 Degrees Slope', color: '#ffff00' },
      { label: '10-15 Degrees Slope', color: '#f5c248' },
      { label: '15-21 Degrees Slope', color: '#f79448' },
      { label: '21-31 Degrees Slope', color: '#f27535' },
      { label: '31-90 Degrees Slope', color: '#9c5a31' },
    ],
  };

  private destroy$ = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    @Inject(RollbarService) private rollbar: Rollbar,
    private alertService: AlertService,
    private eventStreamService: EventStreamService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    if (this.absolutePosition) {
      for (const [key, val] of Object.entries(this.absolutePosition)) {
        const stringValue = typeof val === 'number' ? `${val}px` : val;

        this.absolutePosition[key] = stringValue;
      }
    }

    this.transectMap$
      .pipe(
        filter((map) => Boolean(map)),
        switchMap(
          (transectMap) =>
            this.baseLayer$?.pipe(
              map((baseLayer) => ({ transectMap, baseLayer })),
            ) ?? NEVER,
        ),
        tap(({ transectMap, baseLayer }) => {
          this.isControlReady$.next(false);

          if (transectMap?.getStyle()?.sprite !== baseLayer) {
            transectMap?.setStyle(baseLayer, {
              diff: false,
            });
          }
        }),
        delay(100),
        switchMap(({ transectMap, baseLayer }) => {
          return (
            transectMap?.styleLoaded$.pipe(
              map(() => ({ baseLayer, transectMap })),
            ) ?? NEVER
          );
        }),
        tap(({ transectMap, baseLayer }) => {
          if (baseLayer === this.SATELLITE_STREETS_3D) {
            if (!transectMap.getSource('mapbox-dem')) {
              transectMap.addSource('mapbox-dem', {
                type: 'raster-dem',
                url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
                tileSize: 512,
                maxzoom: 14,
              });

              transectMap.addLayer({
                id: 'sky',
                type: 'sky',
                paint: {
                  'sky-type': 'atmosphere',
                  'sky-atmosphere-sun': [0.0, 0.0],
                  'sky-atmosphere-sun-intensity': 15,
                },
              });

              transectMap.setTerrain({
                source: 'mapbox-dem',
                exaggeration: 1.5,
              });
              transectMap.setPitch(85);
            }
          } else {
            transectMap.setPitch(0);
          }
        }),
        tap(() => {
          this.isControlReady$.next(true);
          this.cd.detectChanges();
        }),
        switchMap(({ baseLayer }) => {
          return this.eventStreamService.publish('BaseLayer', 'view', {
            label: this.baseLayerLabel(baseLayer),
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.transectMap$
      .pipe(
        filter((transectMap) => Boolean(transectMap)),
        switchMap((transectMap) => {
          return transectMap?.on$('error') ?? NEVER;
        }),
        debounceTime(1000),
        tap((error) => {
          if (!error.sourceId) {
            return;
          }

          const errorLayer = this.baseLayers.find((layer) => {
            if (typeof layer.value === 'object') {
              return !!layer.value.sources[error.sourceId as string];
            }

            return false;
          });

          if (errorLayer?.label) {
            this.alertService.showError(
              `Sorry, the USGS servers cannot provide data right now; please try again later.`,
            );
            this.rollbar.error(
              `${error.error.message} - ${errorLayer?.label}` as LogArgument,
            );
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.baseLayerId$ = this.baseLayer$?.pipe(
      map((baseLayer) => this.getBaseLayerId(baseLayer)),
      filter((baseLayerId): baseLayerId is NonNullable<typeof baseLayerId> =>
        Boolean(baseLayerId),
      ),
    );

    this.currentLegendItems$ = this.baseLayerId$?.pipe(
      map((baseLayerId) => this.legendItems[baseLayerId]),
      takeUntil(this.destroy$),
    );
  }

  onBaseLayerSelectionChange(selectChangeEvent: MatSelectChange): void {
    if (
      typeof selectChangeEvent.value === 'string' ||
      this.isValidStyle(selectChangeEvent.value)
    ) {
      this.baseLayer$?.next(selectChangeEvent.value);
    }
  }

  // Type Guards for MatSelectChange.value
  private isValidStyle(value: unknown): value is Style {
    return (value as Style) !== undefined;
  }

  private baseLayerLabel(baseLayer: Style | string): string | undefined {
    return this.baseLayers.find((layer) => layer.value === baseLayer)?.label;
  }

  private getBaseLayerId(baseLayer: Style | string): string | undefined {
    return this.baseLayers.find((layer) => layer.value === baseLayer)?.id;
  }
}
