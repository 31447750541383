import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { OldGeometrySchema } from '../../utils/geometry-object-schema';

const Geography = z.object({
  _id: z.string().uuid(),
  type: z.string().nullable(),
  gnis: z.string().nullable(),
  geoid: z.string().nullable(),
  fips: z.string().nullable(),
  fips_class: z.string().nullable(),
  mtfcc: z.string().nullable(),
  name: z.string().nullable(),
  abbreviation: z.string().nullable(),
  description: z.string().nullable(),
  geometry: OldGeometrySchema.nullable(),
  active: z.boolean().default(true),
  state_species_supported: z.boolean().default(true),
  parent__id: z.string().uuid().nullable(),
  aoi_buffer_intersection_geojson_string: z.string().nullable().optional(),
  intersects_aoi: z.boolean().nullable().optional(),
  intersects_buffer: z.boolean().nullable().optional(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.nullable(),
});

export const GeographyDTO = Geography.extend({
  parent: Geography.optional().nullable(),
});

export type GeographyDTO = z.infer<typeof GeographyDTO>;
