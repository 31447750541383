import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransectFormStateService } from './transect-form-state.service';
import { DialogModalModule, TransectButtonsModule } from '@transect-nx/ui';
import { BtnLoaderModule } from '../../shared/directives/btn-loader/btn-loader.module';
import { DetectScrollToEndModule } from '../detect-scroll-to-end/detect-scroll-to-end.module';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { SharedModule } from '../../shared/shared.module';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { FormModalInputData } from '../../models/form-modal.service';
import {
  catchError,
  EMPTY,
  filter,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { FormsApiService } from '../../services/backend-api/forms.service';
import { FormTypesService } from '../../services/backend-api/form-types.service';
import {
  GetFormFormTypeVersionResponse,
  GetFormTypeLatestVersionResponse,
} from '@transect-nx/data-transfer-objects';
import { delay, map, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { SkeletonLoadingModule } from '../skeleton-loading/skeleton-loading.module';
import { FormTypeKey } from '@transect-nx/models';
import { ProjectService } from '../../services/project.service';
import { AlertService } from '../../services/alert.service';
import { FormMarketplaceSiteMapModule } from '../form-marketplace-site-map/form-marketplace-site-map.module';
import { MapModule } from '../map/map.module';
import { FormModalResult } from '../../services/form-modal.service';

@Component({
  selector: 'ts-form-modal',
  standalone: true,
  imports: [
    CommonModule,
    DialogModalModule,
    BtnLoaderModule,
    DetectScrollToEndModule,
    FormsModule,
    MatDatepickerModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    ReactiveFormsModule,
    SharedModule,
    TransectButtonsModule,
    SkeletonLoadingModule,
    FormMarketplaceSiteMapModule,
    MapModule,
  ],
  providers: [TransectFormStateService, MatDatepickerModule],
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss'],
})
export class FormModalComponent implements OnDestroy, OnInit {
  private readonly destroyAction = new Subject<void>();

  protected completedOn$ = this.formStateService.completedOn$;
  protected canSave$ = this.formStateService.canSave$;
  protected isComplete$ = this.formStateService.isComplete$;
  protected inProgress$ = this.formStateService.inProgress$;
  protected completing$ = this.formStateService.completing$;
  protected isFetchingPdf$ = this.formStateService.isFetchingPdf$;
  protected saveButtonColor$: Observable<'primary' | 'muted'> =
    this.formStateService.saveButtonColor$;
  protected isInitializing = true;

  protected formGroup = new FormGroup<
    Record<string, AbstractControl<string | number | boolean | Date | null>>
  >({});

  protected formTypeVersion$?: Observable<
    GetFormTypeLatestVersionResponse | GetFormFormTypeVersionResponse
  >;
  protected readonly FormTypeKey = FormTypeKey;

  constructor(
    private alertService: AlertService,
    private projectService: ProjectService,
    private formStateService: TransectFormStateService,
    private formsApiService: FormsApiService,
    private formTypesService: FormTypesService,
    private dialogRef: MatDialogRef<FormModalComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: FormModalInputData,
  ) {}

  ngOnInit(): void {
    this.dialogRef
      .backdropClick()
      .pipe(takeUntil(this.destroyAction))
      .subscribe(() => {
        this.handleOnCloseClick();
      });

    this.formStateService.resetToInitialState();
    this.formStateService.dispatchFormIsReadOnly(this.data.readOnly ?? false);
    this.formStateService.dispatchAllowEditAfterCompletion(
      this.data.allowEditAfterCompletion ?? false,
    );
    this.formStateService.registerEffects();

    const formData$ = this.data.formId
      ? this.formsApiService.getFormById(this.data.formId).pipe(shareReplay(1))
      : this.formsApiService
          .getFormByFormTypeKey(this.data.formTypeKey, {
            addOnId: this.data.addOnId,
          })
          .pipe(shareReplay(1));

    this.formTypeVersion$ = formData$.pipe(
      switchMap((form) =>
        form?._id
          ? this.formsApiService.getFormFormTypeVersion(form._id)
          : this.formTypesService.getFormTypeLatestVersion(
              this.data.formTypeKey,
            ),
      ),
      tap((formTypeVersion) => {
        if (this.data.formTypeKey !== formTypeVersion.form_type.key) {
          throw new Error(
            `Form type key mismatch. Expected ${this.data.formTypeKey}, got ${formTypeVersion.form_type.key}`,
          );
        }
      }),
      shareReplay(1),
    );

    const initForm$ = this.formTypeVersion$.pipe(
      tap((formTypeVersion) => {
        const optionalFields = this.getOptionalFields(
          formTypeVersion.model.clauses,
        );

        const entries = Object.entries(formTypeVersion.fields);

        entries.forEach(([key, field]) => {
          if (['string', 'date'].includes(field)) {
            this.formGroup.addControl(
              key,
              new FormControl(
                { value: '', disabled: key === 'parcelIdentificationNumber' },
                key === 'email' ? [Validators.email] : [],
              ),
            );

            if (!optionalFields.includes(key)) {
              this.formGroup.get(key)?.addValidators(Validators.required);
            }
          } else if (field === 'boolean') {
            this.formGroup.addControl(
              key,
              new FormControl(false, [Validators.required]),
            );
          } else if (field === 'number') {
            this.formGroup.addControl(
              key,
              new FormControl(
                formTypeVersion.form_type.key === FormTypeKey.TESS_SCOPE_OF_WORK
                  ? 1
                  : null,
                [Validators.required, Validators.pattern(/^\d+$/)],
              ),
            );
          }
        });
      }),
      withLatestFrom(formData$),
      tap(([formTypeVersion, form]) => {
        if (form && form._id) {
          this.formGroup.patchValue(form.contents ?? {}, {
            emitEvent: false,
          });

          this.formStateService.dispatchBackendFormStateAction(
            form._id,
            form.contents,
            !!form.completed_on,
            form.completed_on,
          );
        } else {
          this.formStateService.dispatchFormValueChangeAction(
            this.isFormValid(),
            this.formGroup.getRawValue(),
            formTypeVersion._id,
            this.data.addOnId,
          );
        }
      }),
      delay(0),
      tap(() => {
        this.isInitializing = false;
      }),
      delay(0),
      tap(() => {
        if (
          (this.formStateService.stateValue.isComplete &&
            !this.data.allowEditAfterCompletion) ||
          this.data.readOnly
        ) {
          this.formGroup.disable();
        }

        this.formStateService.dispatchFormValidAction(this.isFormValid());
      }),
      shareReplay(1),
    );

    initForm$
      .pipe(
        switchMap(([formTypeVersion]) =>
          this.formGroup.valueChanges.pipe(
            filter(() => this.formGroup.dirty),
            map(() => ({
              formTypeVersion,
              formValue: this.formGroup.getRawValue(),
            })),
          ),
        ),
        switchMap(({ formTypeVersion, formValue }) => {
          return this.formStateService.dispatchFormValueChangeAction(
            this.isFormValid(),
            formValue,
            formTypeVersion._id,
          );
        }),
        takeUntil(this.destroyAction),
      )
      .subscribe();

    initForm$
      .pipe(
        filter(
          ([formTypeVersion]) =>
            formTypeVersion.form_type.key ===
            FormTypeKey.SITE_ACCESS_AUTHORIZATION,
        ),
        switchMap(() => {
          const parcelIdentificationNumber =
            this.formStateService.stateValue.formValue?.[
              'parcelIdentificationNumber'
            ];

          if (parcelIdentificationNumber || !this.data.projectId) {
            return EMPTY;
          }

          return this.projectService
            .getSiteAccessInfo(this.data.projectId)
            .pipe(
              catchError((error) => {
                this.alertService.showError(error);

                return EMPTY;
              }),
            );
        }),
        map((siteAccessInfo) => {
          const parcelNumbers = siteAccessInfo.parcel_numbers.join(', ');
          return {
            parcelIdentificationNumber: parcelNumbers || 'N/A',
          };
        }),
        tap(({ parcelIdentificationNumber }) => {
          this.formGroup
            .get('parcelIdentificationNumber')
            ?.setValue(parcelIdentificationNumber, {
              emitEvent: true,
            });
        }),
        takeUntil(this.destroyAction),
      )
      .subscribe();
  }

  private isFormValid() {
    if (this.data.formTypeKey === FormTypeKey.ALTA_SCOPE_OF_WORK) {
      return (
        this.formGroup.valid &&
        Object.values(this.formGroup.getRawValue()).some((value) => !!value)
      );
    }
    return this.formGroup.valid;
  }

  ngOnDestroy(): void {
    this.destroyAction.next();
    this.formStateService.unRegisterEffects();
  }

  handleScrollToEnd() {
    this.formStateService.dispatchScrolledToEndAction();
  }

  handleDownloadPdfClick() {
    this.formStateService.dispatchPdfDownloadAction().subscribe();
  }

  handleSaveClick() {
    if (!this.isFormValid()) {
      return;
    }

    const preprocess$ = (formTypeVersionId: string) => {
      switch (this.data.formTypeKey) {
        case FormTypeKey.FREE_TRIAL_AGREEMENT:
        case FormTypeKey.LANDOWNER_CONTACT_TOC:
          return this.formStateService.dispatchFormValueChangeAction(
            true,
            { agreed: true },
            formTypeVersionId,
          );
        default:
          return of(null);
      }
    };

    this.formTypeVersion$
      ?.pipe(
        switchMap((formTypeVersion) => preprocess$(formTypeVersion._id)),
        switchMap(() => this.formStateService.dispatchCompleteFormAction()),
      )
      ?.subscribe(() => {
        this.closeModal({
          formId: this.formStateService.stateValue.backendFormId,
          isComplete: true,
        });
      });
  }

  private closeModal(data: FormModalResult) {
    this.dialogRef.close(data);
  }

  protected handleOnCloseClick() {
    const isComplete = this.formStateService.stateValue.isComplete;
    const formId = this.formStateService.stateValue.backendFormId;

    this.dialogRef.close(
      isComplete ? { formId, isComplete } : { isComplete: false },
    );
  }

  protected navigateLeft(formControlName: string) {
    const control = this.formGroup.get(formControlName);
    if (!control) {
      return;
    }

    const currentValue = Number(control.value);

    if (currentValue > 1) {
      this.formGroup.markAsDirty();
      this.formGroup.patchValue(
        { [formControlName]: currentValue - 1 },
        {
          emitEvent: true,
        },
      );
    }
  }

  protected navigateRight(formControlName: string, carouselLength: number) {
    const control = this.formGroup.get(formControlName);
    if (!control) {
      return;
    }
    const currentValue = Number(control.value);
    if (currentValue < carouselLength) {
      this.formGroup.markAsDirty();
      this.formGroup.patchValue(
        { [formControlName]: currentValue + 1 },
        {
          emitEvent: true,
        },
      );
    }
  }

  private getOptionalFields(
    clauses: GetFormTypeLatestVersionResponse['model']['clauses'],
  ): string[] {
    const optionalFields: string[] = [];

    clauses.forEach((clause) => {
      clause.fragments.forEach((fragment) => {
        if (
          (fragment.type === 'input' || fragment.type === 'checkbox') &&
          fragment.optional
        ) {
          optionalFields.push(fragment.name);
        }
      });
    });

    return optionalFields;
  }
}
