import { Inject, Injectable } from '@angular/core';
import { kml as toGeojson } from '@tmcw/togeojson';
import * as turf from '@turf/turf';
import { AllGeoJSON, FeatureCollection } from '@turf/turf';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { FileReaderService } from './file-reader.service';
import { RollbarService } from './rollbar.service';
import Rollbar from 'rollbar';

@Injectable({
  providedIn: 'root',
})
export class KmlHelperService {
  constructor(
    private fileReaderService: FileReaderService,
    private alertService: AlertService,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  rollbarLog(message: string) {
    this.rollbar.info(message);
  }

  /** Supports KML and KMZ */
  kmlFileToGeojson(kmlFile: File): Observable<AllGeoJSON | null> {
    this.rollbarLog(
      `beginning kmlFileToGeojson: ${kmlFile.name} (${kmlFile.size / 1024} KB)`,
    );
    return this.fileReaderService.readFileAsText(kmlFile).pipe(
      switchMap((kml) => {
        if (!kml) {
          return of(null);
        }

        const cleanedKml = this.cleanKml(kml);

        this.rollbarLog('cleaned kml');

        const domParser = new DOMParser();
        const dom = domParser.parseFromString(cleanedKml, 'text/xml');

        this.rollbarLog(
          `parsed kml namespaceURI: ${dom.documentElement.namespaceURI}`,
        );

        this.rollbarLog(
          `total Placemark elements: ${
            dom.getElementsByTagName('Placemark').length
          }`,
        );
        this.rollbarLog(
          `total Folder elements: ${dom.getElementsByTagName('Folder').length}`,
        );
        this.rollbarLog(
          `total Document elements: ${
            dom.getElementsByTagName('Document').length
          }`,
        );
        this.rollbarLog(
          `total Polygon elements: ${
            dom.getElementsByTagName('Polygon').length
          }`,
        );
        this.rollbarLog(
          `total LineString elements: ${
            dom.getElementsByTagName('LineString').length
          }`,
        );

        const coordinatesElements = dom.getElementsByTagName('coordinates');
        let totalCoordinates = 0;

        for (let i = 0; i < coordinatesElements.length; i++) {
          const element = coordinatesElements[i];
          totalCoordinates += element?.textContent?.split(' ').length ?? 0;
        }

        this.rollbarLog(`total coordinates found: ${totalCoordinates}`);

        const errors = dom.getElementsByTagName('parsererror');

        this.rollbarLog(`total errors: ${errors.length}`);

        if (errors.length > 0) {
          let errorMessage = ``;
          Array.from(errors).forEach((error) => {
            const messageElements = error.getElementsByTagName('div');

            Array.from(messageElements).forEach((messageElement) => {
              const message = messageElement.innerText;

              errorMessage += message;
            });
          });

          this.rollbarLog(`error message: ${errorMessage}`);

          return throwError(
            () => new Error(`Failed to parse the KML. ${errorMessage}`),
          );
        }

        return of(dom);
      }),
      map((dom) => {
        if (!dom) {
          return null;
        }

        const geojson = toGeojson(dom) as AllGeoJSON;

        return geojson;
      }),
      catchError((error) => {
        this.alertService.showError(error);
        throw error;
      }),
    );
  }

  kmlFileToFeatureCollection(kmlFile: File): Observable<FeatureCollection> {
    return this.kmlFileToGeojson(kmlFile).pipe(
      map((geojson) => {
        if (!geojson) {
          return turf.featureCollection([]);
        }

        const featureCollection = turf.flatten(geojson) as FeatureCollection;

        this.rollbarLog(
          `found features: ${featureCollection?.features?.length}`,
        );
        return featureCollection;
      }),
    );
  }

  /*
   * let's imagine that this regular expression is a toy robot you just programmed. You told your robot to look for your toy blocks that have a special label on them, "xsi:".
   * The instructions you gave your robot are:
   * Start looking for the label "xsi:" but make sure it's at the very beginning of a block, not in the middle or at the end.
   * If you find a space before "xsi:", take that as well. If there's no space, that's fine too, just look for the "xsi:" label.
   * After finding "xsi:", continue to look at the rest of the block, but stop when you see the equals sign "=".
   * Now, you told your robot that there might be a sticker after the "=" sign. This sticker is placed between two double-quote marks (like this ""). The sticker might have a picture (any characters) on it or it might be blank (no characters), it doesn't matter.
   * You want your robot to pick up the whole toy block that starts with "xsi:" and ends with the sticker ("").
   * Finally, you asked your robot to do this for all the blocks in your room, not just for the first block it finds.
   */
  cleanKml(kml: string): string {
    return kml.replace(/(\s)?\bxsi:[^"]*="[^"]*"/g, '');
  }
}
