import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { FormTypeDesignation } from '@transect-nx/models';

export const FormDTO = z.object({
  _id: z.string().uuid().nullish(),
  form_type__id: z.string().uuid(),
  form_type_version__id: z.string().uuid(),
  creator__id: z.string().uuid(),
  completed_by__id: z.string().uuid().nullish(),
  contents: z.record(
    z.string(),
    z.union([z.string(), z.number(), z.boolean(), DateSchema]).nullish(),
  ),
  completed_on: DateSchema.nullish(),
  form_type: z
    .object({
      designation: z.nativeEnum(FormTypeDesignation),
    })
    .optional(),
});

export type FormDTO = z.infer<typeof FormDTO>;
