import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { OldGeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const CatalogLocationProperties = z.object({}).passthrough();

const CatalogGroup = z.object({
  _id: z.string().uuid(),
  name: z.string().nullable(),
  description: z.string().nullable(),
});

const CatalogItem = z.object({
  _id: z.string().uuid(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  category: z.string().nullable(),
  key: z.string().nullable(),
  properties: z.object({}).passthrough().nullable(),
  catalog_group: CatalogGroup.deepPartial(),
});

export const ReportPartCatalogLocationFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  properties: CatalogLocationProperties.deepPartial().nullable(),
  geometry: OldGeometrySchema,
  source__id: z.string().uuid(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  has_intersection_aoi: z.boolean(),
  has_intersection_buffer: z.boolean(),
  reference: z.object({}).passthrough().nullable(),
  catalog_item: CatalogItem.deepPartial(),
  transect_feature_type: z.literal('catalog_location'),
  transect_summary: TransectSummarySchema,
});

export type ReportPartCatalogLocationFeatureDTO = z.infer<
  typeof ReportPartCatalogLocationFeatureDTO
>;
