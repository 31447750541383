<ng-container
  *ngIf="formTypeVersion$ | async as formTypeVersion; else skeletonLoading"
>
  <transect-nx-dialog-modal
    [title]="formTypeVersion.form_type.name"
    [footer]="true"
    [footerTemplate]="footerTemplate"
    [contentTemplate]="contentTemplate"
    [disableClose]="true"
    (closeClicked)="handleOnCloseClick()"
  >
  </transect-nx-dialog-modal>
</ng-container>

<ng-template #skeletonLoading>
  <div class="skeleton-container">
    <ts-skeleton-line-loading
      [lineGap]="1"
      [count]="30"
      [height]="2"
    ></ts-skeleton-line-loading>
  </div>
</ng-template>
<ng-template #contentTemplate>
  <div
    *ngIf="formTypeVersion$ | async as formTypeVersion"
    class="main-container"
  >
    <form
      [formGroup]="formGroup"
      tsFormLoader
      [loaderState]="(completing$ | async) || isInitializing"
      tsDetectScrollToEnd
      (scrollEnd)="handleScrollToEnd()"
    >
      <ng-container *ngFor="let clause of formTypeVersion.model.clauses">
        <div class="model-clause">
          <ng-container *ngFor="let fragment of clause.fragments">
            <ng-container *ngIf="fragment.type === 'carousel'">
              <input
                type="radio"
                [hidden]="true"
                [formControlName]="fragment.name"
                [value]="fragment.value"
              />

              <ng-container
                *ngIf="formGroup.controls?.[fragment.name]?.value === fragment.value"
              >
                <span> Choose an appropriate scope for your order </span>

                <div class="option-controls-container">
                  <button
                    transect-nx-button
                    buttonStyle="outlined"
                    (click)="navigateLeft(fragment.name)"
                    [disabled]="formGroup.disabled"
                  >
                    <i class="fas fa-chevron-left text-shades-gray-dark"></i>
                  </button>

                  <span>Option {{ fragment.value }}</span>

                  <button
                    transect-nx-button
                    buttonStyle="outlined"
                    (click)="
                      navigateRight(fragment.name, clause.fragments.length)
                    "
                    [disabled]="formGroup.disabled"
                  >
                    <i class="fas fa-chevron-right text-shades-gray-dark"></i>
                  </button>
                </div>
              </ng-container>

              <ng-container *ngFor="let fragmentClause of fragment.clauses">
                <div
                  *ngIf="formGroup.controls?.[fragment.name]?.value === fragment.value"
                >
                  <ng-container
                    *ngFor="let clauseFragment of fragmentClause.fragments"
                  >
                    <span
                      [ngStyle]="{
                        paddingLeft: (clauseFragment.shift ?? 0) * 2 + 'rem',
                        fontWeight: clauseFragment.fontWeight,
                        color: clauseFragment.color
                      }"
                      class="body-medium model-fragment"
                    >
                      {{ clauseFragment.text }}
                    </span>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="fragment.type === 'text'">
              <span
                [ngStyle]="{
                  paddingLeft: (fragment.shift ?? 0) * 2 + 'rem',
                  fontWeight: fragment.fontWeight,
                  color: fragment.color
                }"
                class="body-medium model-fragment"
                [innerHTML]="fragment.text"
              >
              </span>
            </ng-container>

            <ng-container *ngIf="fragment.type === 'input'">
              <div class="form-field-container">
                <span class="body-medium model-fragment">{{
                  fragment.label
                }}</span>
                <ng-container
                  *ngIf="formTypeVersion.fields[fragment.name] === 'string'"
                >
                  <ng-container [ngSwitch]="fragment.name">
                    <ng-container
                      *ngSwitchCase="
                        [
                          'engineeringDesignSurvey',
                          'anythingElseToKnow',
                          'whenDoYouNeedHelp'
                        ].includes(fragment.name)
                          ? fragment.name
                          : 'engineeringDesignSurvey'
                      "
                    >
                      <mat-form-field appearance="outline" floatLabel="never">
                        <textarea
                          matInput
                          cdkTextareaAutosize
                          [formControlName]="fragment.name"
                          cdkAutosizeMinRows="2"
                          cdkAutosizeMaxRows="5"
                        ></textarea>
                      </mat-form-field>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                      <mat-form-field class="form-field" appearance="outline">
                        <input
                          matInput
                          type="text"
                          [formControlName]="fragment.name"
                          [placeholder]="
                            fragment.name === 'parcelIdentificationNumber'
                              ? 'Loading...'
                              : ''
                          "
                          required
                        />
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container
                  *ngIf="formTypeVersion.fields[fragment.name] === 'date'"
                >
                  <mat-form-field class="form-field" appearance="outline">
                    <input
                      matInput
                      [matDatepicker]="date_picker"
                      [formControlName]="fragment.name"
                      required
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="date_picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #date_picker></mat-datepicker>
                    <mat-hint>MM/DD/YYYY</mat-hint>
                  </mat-form-field>
                </ng-container>
              </div>
            </ng-container>

            <ng-container *ngIf="fragment.type === 'checkbox'">
              <div class="form-field-container">
                <label
                  class="body-medium model-fragment"
                  [ngStyle]="{
                  paddingLeft: (fragment.shift ?? 0) * 2 + 'rem',
                }"
                >
                  <mat-checkbox
                    [formControlName]="fragment.name"
                  ></mat-checkbox>
                  <span class="checkbox-text">
                    {{ fragment.label }}
                  </span>
                </label>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </form>

    <ts-form-marketplace-site-map
      *ngIf="data.projectGeometry"
      class="map-container"
      [projectId]="data.projectId"
      [projectGeometry]="data.projectGeometry"
    ></ts-form-marketplace-site-map>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <ng-container *ngIf="isInitializing; else footerContents">
    <button
      transect-nx-button
      buttonStyle="contained"
      color="muted"
      size="md"
      tsBtnLoader
      [loaderState]="true"
    >
      Loading...
    </button>
  </ng-container>

  <ng-template #footerContents>
    <ng-container
      *ngIf="
        ((isComplete$ | async) === false || data.allowEditAfterCompletion) &&
          !data.readOnly;
        else downloadPdf
      "
    >
      <button
        transect-nx-button
        [color]="(saveButtonColor$ | async) ?? 'primary'"
        buttonStyle="contained"
        size="md"
        tsBtnLoader
        [loaderState]="(inProgress$ | async) === true"
        (click)="handleSaveClick()"
        [disabled]="(canSave$ | async) === false"
      >
        <ng-container [ngSwitch]="data.formTypeKey">
          <ng-container *ngSwitchCase="FormTypeKey.MARKETPLACE_AGREEMENT">
            Yes, I agree
          </ng-container>
          <ng-container *ngSwitchCase="FormTypeKey.LANDOWNER_CONTACT_TOC">
            Yes, I agree
          </ng-container>
          <ng-container *ngSwitchDefault> Save</ng-container>
        </ng-container>
      </button>
      <span
        *ngIf="(isComplete$ | async) === false"
        class="body-small text-shades-gray-dark"
      >
        Please fill out and scroll all the way to the bottom to save
      </span>
    </ng-container>

    <ng-template #downloadPdf>
      <button
        transect-nx-button
        buttonStyle="contained"
        size="md"
        (click)="handleDownloadPdfClick()"
        tsBtnLoader
        [loaderState]="(isFetchingPdf$ | async) === true"
        [disabled]="(isComplete$ | async) === false"
      >
        Download PDF
      </button>
      <span class="date-text" *ngIf="completedOn$ | async as completedOn"
        >Completed on {{ completedOn | date : 'MM/dd/yy' }}</span
      >
      <span class="date-text" *ngIf="(isComplete$ | async) === false">
        Not completed
      </span>
    </ng-template>
  </ng-template>
</ng-template>
