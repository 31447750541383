import { z } from 'zod';

export const SolarPulseRequestDTO = z.object({
  projectId: z.string().uuid().nullish(),
  parcelFilterId: z.string().uuid().nullish(),
  geojsonString: z.string().nullish(),
  fetchExpiredMoratoriums: z.boolean().default(false),
  geographyId: z.string().uuid().nullish(),
});

export type SolarPulseRequestDTO = z.infer<typeof SolarPulseRequestDTO>;
