import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import {
  CoordinatesSchema,
  OldGeometrySchema,
} from '../../utils/geometry-object-schema';
import { stringToBoolean } from '../../utils/string-to-boolean';
import { safeNumber } from '../../utils/string-to-number';
import { CustomerDTO } from '../customers';
import { GeographyState } from '../geographies';
import { ReportConcernLevel } from '../reports/report.dto';
import { ProjectContext } from './project.context.dto';
import { Preferences } from './project.dto';
import { ProjectsScope } from '@transect-nx/models';

export const MyVisionProjectsQueryDTO = z
  .object({
    search: z.string().nullable(),
    pageSize: safeNumber.nullable(),
    cursor: z
      .preprocess(
        (val) => {
          return val && JSON.parse(decodeURIComponent(val as string));
        },
        z
          .object({
            _id: z.string().uuid(),
            created_at: DateSchema,
            pinned: z.boolean(),
          })
          .partial()
          .nullish(),
      )
      .nullable(),
    projectsScope: z.nativeEnum(ProjectsScope).nullable(),
    entityId: z.string().uuid().nullable(),
    refresh: stringToBoolean.nullable(),
  })
  .deepPartial();

export type MyVisionProjectsQueryDTO = z.infer<typeof MyVisionProjectsQueryDTO>;

const Report = z.object({
  _id: z.string().uuid(),
  status: z.string(),
  concern_levels: z.record(ReportConcernLevel.deepPartial()).nullish(),
});

const ProjectRole = z.enum(['owner', 'viewer', 'collaborator', 'tester']);

export const MyVisionProjectViewEntity = z
  .object({
    _id: z.string().uuid(),
    preferences: Preferences.nullable(),
    user__id: z.string().uuid(),
    project_role: ProjectRole.nullable(),
    name: z.string(),
    pinned: z.boolean(),
    entity_type: z.enum(['project', 'folder']),
    geometry: OldGeometrySchema.extend({
      coordinates: CoordinatesSchema.nullish(),
    }).nullish(),
    description: z.string().nullable(),
    client_identifier: z.string().nullable(),
    properties: z.any().nullish(),
    context: ProjectContext.partial().nullish(),
    active: z.boolean(),
    geographies_states: z.array(GeographyState).nullable(),
    created_from: z.string(),
    creator__id: z.string().uuid().nullable(),
    updated_at: DateSchema,
    created_at: DateSchema,
    customer__id: z.string().uuid().nullable(),
    customer: CustomerDTO.pick({
      name: true,
      all_collaborator: true,
    }).nullable(),
    report: Report.nullable(),
    owner: z.string().nullable(),
    owner_active: z.boolean().nullable(),
    reports: z.array(Report).max(1),
  })
  .partial();

export const VisionProjectGeometryDTO = z.object({
  geometry: OldGeometrySchema.extend({
    coordinates: CoordinatesSchema,
  }),
});

export const VisionProjectMultipleWithGeometry = z.object({
  _id: z.string().uuid(),
  geometry: OldGeometrySchema.extend({
    coordinates: CoordinatesSchema.nullable(),
  }).nullable(),
});

export const GetProjectsMultipleWithGeometryQueryDTO = z.object({
  projectIds: z.array(z.string().uuid()),
});

export const VisionProjectsMultipleWithGeometryDTO = z.array(
  VisionProjectMultipleWithGeometry,
);

export const MyVisionProjectsViewEntities = z.array(
  MyVisionProjectViewEntity.partial(),
);

export type MyVisionProjectViewEntity = z.infer<
  typeof MyVisionProjectViewEntity
>;

export type VisionProjectsMultipleWithGeometryDTO = z.infer<
  typeof VisionProjectsMultipleWithGeometryDTO
>;

export type MyVisionProjectsViewEntities = z.infer<
  typeof MyVisionProjectsViewEntities
>;

export type VisionProjectGeometryDTO = z.infer<typeof VisionProjectGeometryDTO>;

export type GetProjectsMultipleWithGeometryQueryDTO = z.infer<
  typeof GetProjectsMultipleWithGeometryQueryDTO
>;
