import { Injectable } from '@angular/core';
import { FormTypeKey, TransectPlanKey } from '@transect-nx/models';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { FormTypesService } from './backend-api/form-types.service';
import { FormsApiService } from './backend-api/forms.service';
import { SessionClaimsService } from './session-claims.service';
import { FormModalService } from './form-modal.service';

@Injectable({
  providedIn: 'root',
})
export class FreeTrialAgreementService {
  constructor(
    private sessionClaimsService: SessionClaimsService,
    private formModalService: FormModalService,
    private formTypesService: FormTypesService,
    private formsApiService: FormsApiService,
  ) {}

  formType$ = this.formTypesService
    .getFormTypeByKey(FormTypeKey.FREE_TRIAL_AGREEMENT)
    .pipe(shareReplay(1));

  form$ = this.formsApiService.getFormByFormTypeKey(
    FormTypeKey.FREE_TRIAL_AGREEMENT,
  );

  checkFreeTrialAgreement(): Observable<boolean> {
    const isFreeUser$ = this.sessionClaimsService.isTransectPlan$(
      TransectPlanKey.Free,
    );
    const isSelfServeFreeTrialUser$ = this.sessionClaimsService.isTransectPlan$(
      TransectPlanKey.SelfServeFreeTrial,
    );

    return combineLatest([isFreeUser$, isSelfServeFreeTrialUser$]).pipe(
      take(1),
      switchMap(([isFreeUser, isSelfServeFreeTrialUser]) => {
        if (!isFreeUser && !isSelfServeFreeTrialUser) {
          return of(true);
        }
        return this.form$.pipe(
          switchMap((form) => {
            if (!form || !form.completed_on) {
              return this.formModalService
                .open({
                  formTypeKey: FormTypeKey.FREE_TRIAL_AGREEMENT,
                })
                .pipe(map(() => true));
            }
            return of(true);
          }),
        );
      }),
    );
  }
}
