import { z } from 'zod';
import { OldGeometrySchema } from '../../../utils/geometry-object-schema';
import { GeographyDTO } from '../geography-dto';

const Geography = z.object({
  _id: z.string().uuid(),
  type: z.string().nullish(),
  gnis: z.string().nullish(),
  geoid: z.string().nullish(),
  fips: z.string().nullish(),
  fips_class: z.string().nullish(),
  mtfcc: z.string().nullish(),
  name: z.string().nullish(),
  abbreviation: z.string().nullish(),
  description: z.string().nullish(),
  geometry: OldGeometrySchema.nullish(),
  active: z.boolean().optional(),
  state_species_supported: z.boolean().optional(),
  parent__id: z.string().uuid().nullish(),
});

export const PatchGeographiesDTO = z.array(Geography);

export type PatchGeographiesDTO = z.infer<typeof PatchGeographiesDTO>;
