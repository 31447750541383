import { NgModule } from '@angular/core';
import { MapService } from '../map/services/map.service';
import { FormMarketplaceSiteMapComponent } from '../form-marketplace-site-map/form-marketplace-site-map.component';

@NgModule({
  declarations: [FormMarketplaceSiteMapComponent],
  providers: [MapService],
  exports: [FormMarketplaceSiteMapComponent],
})
export class FormMarketplaceSiteMapModule {}
