import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  EntityTypeForComplexGeometryDTO,
  GetOrdersIdCheckGeometryComplexityResponseDTO,
  OldGeometrySchema,
  PatchReportUpdateBodyDTO,
  ReportIndexResponseDTO,
  ReportMineResponseDTO,
  ReportsControllerDTO,
  ReportShowDTO,
  ReportStatusDTO,
  ReportUpdateConcernLevelBodyDTO,
  ReportUpdatedDTO,
} from '@transect-nx/data-transfer-objects';
import { GeometryObject } from '@turf/turf';
import Rollbar from 'rollbar';
import {
  BehaviorSubject,
  EMPTY,
  forkJoin,
  from,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  toArray,
} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiUrls } from '../models/api-urls';
import { Order } from '../models/order';
import { Report, ReportPart } from '../models/report';
import { ResponseRows } from '../models/response-rows';
import { TransectAPIError } from '../models/transect-api-error';
import { UpgradePromptService } from '../modules/upgrade-prompt/upgrade-prompt.service';
import { RollbarService } from './rollbar.service';

type FetchReportParams = {
  project__id?: string;
  is_sales_demo?: boolean;
  search?: string;
  page?: number;
  pageSize?: number;
  sortModel?: { colId: string; sort: 'asc' | 'desc'; orderPriority?: number }[];
  filterModel?: any;
};

@Injectable({
  providedIn: 'root',
})
export class ReportService implements OnDestroy {
  private reportCache: { [id: string]: Observable<ReportShowDTO> | null } = {};
  private destroy$ = new Subject<void>();

  private _focusReportMap = new BehaviorSubject<boolean>(false);
  public focusReportMap$ = this._focusReportMap.asObservable();

  constructor(
    private http: HttpClient,
    private upgradePromptService: UpgradePromptService,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  viewReportMonitor(reportId: string): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/reports/${reportId}/monitor-viewed`,
      {},
    );
  }

  toggleReportMonitor(
    reportId: string,
    body: ReportsControllerDTO.ToggleMonitorBody,
  ): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/reports/${reportId}/toggle-monitor`,
      body,
    );
  }

  changesSinceLastRefresh(
    reportId: string,
  ): Observable<ReportsControllerDTO.ChangesSinceLastRefreshResponse> {
    return this.http
      .get<ReportsControllerDTO.ChangesSinceLastRefreshResponse>(
        `${environment.apiUrl}/reports/${reportId}/changes-since-last-refresh`,
      )
      .pipe(
        map((response) =>
          ReportsControllerDTO.ChangesSinceLastRefreshResponse.parse(response),
        ),
      );
  }

  getMonitoredDataPoints(
    reportId: string,
  ): Observable<ReportsControllerDTO.GetMonitoredDataPointsResponse> {
    return this.http
      .get<ReportsControllerDTO.GetMonitoredDataPointsResponse>(
        `${environment.apiUrl}/reports/${reportId}/monitored-data-points`,
      )
      .pipe(
        map((response) =>
          ReportsControllerDTO.GetMonitoredDataPointsResponse.parse(response),
        ),
      );
  }

  getChangelogs(
    reportId: string,
  ): Observable<ReportsControllerDTO.ChangelogsResponse> {
    return this.http
      .get<ReportsControllerDTO.ChangelogsResponse>(
        `${environment.apiUrl}/reports/${reportId}/changelogs`,
      )
      .pipe(
        map((response) =>
          ReportsControllerDTO.ChangelogsResponse.parse(response),
        ),
      );
  }

  getMyReports(pageSize?: number): Observable<ResponseRows<Report>> {
    return this.http.get<ResponseRows<Report>>('/api/reports/mine', {
      params: {
        pageSize: (pageSize ?? 10).toString(),
      },
    });
  }

  fetchAllMyReports(): Observable<Report[]> {
    return this.http.get<ResponseRows<Report>>('/api/reports/mine').pipe(
      switchMap((response) => from(response.rows)),
      toArray(),
    );
  }

  createReport(params: {
    creator__id: string | null;
    project__id: string;
    type: 'dr' | 'nr';
  }): Observable<Report> {
    return this.http.post<Report>('/api/reports', params);
  }

  fetchAllReports(
    params: FetchReportParams,
  ): Observable<ReportIndexResponseDTO> {
    let httpParams = new HttpParams();

    if (params.search !== undefined) {
      httpParams = httpParams.append('search', params.search);
    }

    if (params.project__id !== undefined) {
      httpParams = httpParams.append('project__id', params.project__id);
    }

    if (params.is_sales_demo !== undefined) {
      httpParams = httpParams.append(
        'is_sales_demo',
        params.is_sales_demo.toString(),
      );
    }

    if (params.page !== undefined) {
      httpParams = httpParams.append('page', params.page);
    }

    if (params.pageSize !== undefined) {
      httpParams = httpParams.append('pageSize', params.pageSize);
    }

    if (params.sortModel !== undefined) {
      httpParams = httpParams.append(
        'sortModel',
        JSON.stringify(params.sortModel),
      );
    }

    if (params.filterModel !== undefined) {
      httpParams = httpParams.append(
        'filterModel',
        JSON.stringify(params.filterModel),
      );
    }

    return this.http
      .get<ReportIndexResponseDTO>(`${environment.apiUrl}/reports`, {
        params: httpParams,
      })
      .pipe(map((response) => ReportIndexResponseDTO.parse(response)));
  }

  getTheirReports(userId: string): Observable<ReportMineResponseDTO> {
    return this.http
      .get<ReportMineResponseDTO>(`${environment.apiUrl}/reports/theirs`, {
        params: {
          user__id: userId,
        },
      })
      .pipe(map((response) => ReportMineResponseDTO.parse(response)));
  }

  saveReport(
    reportId: string,
    report: PatchReportUpdateBodyDTO,
  ): Observable<ReportUpdatedDTO> {
    const parsed = PatchReportUpdateBodyDTO.parse(report);

    return this.http
      .patch<ReportUpdatedDTO>(
        `${environment.apiUrl}/reports/${reportId}`,
        parsed,
      )
      .pipe(map((response) => ReportUpdatedDTO.parse(response)));
  }

  patchReportContext(reportId: string, context: ReportShowDTO['context']) {
    return this.http.patch(
      `${environment.apiUrl}/reports/${reportId}/context`,
      context,
    );
  }

  getMyProjectReports(projectId: string): Observable<ResponseRows<Report>> {
    return this.http.get<ResponseRows<Report>>('/api/reports/mine', {
      params: {
        project__id: projectId,
      },
    });
  }

  getReportParts(reportId: string): Observable<ResponseRows<ReportPart>> {
    return this.http.get<ResponseRows<ReportPart>>(
      ApiUrls.ReportPart.getReportParts(),
      {
        params: {
          report__id: reportId,
        },
      },
    );
  }

  generateReportPart(
    reportId: string,
    reportPart: string,
  ): Observable<ReportPart> {
    return this.http.post<ReportPart>(ApiUrls.ReportPart.generateReportPart(), {
      report__id: reportId,
      type: reportPart,
    });
  }

  saveReportPart(
    reportPart: ReportPart,
    options?: object,
  ): Observable<ReportPart> {
    if (!reportPart._id) {
      throw new Error('ReportPart must have an _id to save');
    }

    return this.http.put<ReportPart>(
      ApiUrls.ReportPart.saveReportPart(reportPart._id),
      reportPart,
      options,
    );
  }

  refreshReportPartsByUser(reportId: string): Observable<ReportPart[]> {
    return this.http.post<ReportPart[]>(
      ApiUrls.ReportPart.refreshReportPartsByUser(reportId),
      {},
    );
  }

  deleteReportPartById(reportPartId: string): Observable<void> {
    return this.http.delete<void>(
      ApiUrls.ReportPart.deleteReportPart(reportPartId),
    );
  }

  getReportPart(reportPartId: string): Observable<ReportPart> {
    return this.http
      .get<ReportPart>(
        `${ApiUrls.ReportPart.getById(reportPartId)}/${reportPartId}`,
      )
      .pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      );
  }

  getStatus(reportId: string): Observable<ReportStatusDTO> {
    return this.http
      .get<ReportStatusDTO>(`${environment.apiUrl}/reports/${reportId}/status`)
      .pipe(map((response) => ReportStatusDTO.parse(response)));
  }

  resetReportCache(reportId: string): void {
    this.reportCache[reportId] = null;
  }

  resetAllReportCache(): void {
    this.reportCache = {};
  }

  getReport(
    reportId: string,
    loadAnnotations?: boolean,
    resetCache?: boolean,
  ): Observable<ReportShowDTO> | null {
    if (resetCache) {
      this.reportCache[reportId] = null;
    }

    this.reportCache[reportId] =
      this.reportCache[reportId] ||
      this.fetchReportWithAnnotations(reportId, loadAnnotations).pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      );

    return this.reportCache[reportId];
  }

  orderReport(transaction: any): Observable<Order> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.rollbar.debug('Starting orderReport', transaction);

    return this.http
      .post<Order>(
        `${environment.apiUrl}/orders/report-subscription`,
        transaction,
      )
      .pipe(
        catchError((error: Error) => {
          if (
            error instanceof TransectAPIError &&
            error.code === 'CustomerReportLimitExceeded'
          ) {
            this.upgradePromptService.showUpgradeReportRequestDialog();
            return EMPTY;
          }

          throw error;
        }),
      );
  }

  duplicateReportWithChanges(
    oldReportId: string,
    geometry: OldGeometrySchema,
    reportTitle?: string,
  ): Observable<Order> {
    return this.http
      .post<Order>(
        `${environment.apiUrl}/orders/${oldReportId}/duplicate-report`,
        { geometry, reportTitle },
      )
      .pipe(
        catchError((error: Error) => {
          if (
            error instanceof TransectAPIError &&
            error.code === 'CustomerReportLimitExceeded'
          ) {
            this.upgradePromptService.showUpgradeReportRequestDialog();
            return EMPTY;
          }

          throw error;
        }),
      );
  }

  checkGeometryComplexity(
    id: string,
    entityType: EntityTypeForComplexGeometryDTO,
  ): Observable<GetOrdersIdCheckGeometryComplexityResponseDTO> {
    return this.http
      .get<GetOrdersIdCheckGeometryComplexityResponseDTO>(
        `${environment.apiUrl}/orders/${id}/check-geometry-complexity`,
        {
          params: {
            entity_type: entityType,
          },
        },
      )
      .pipe(
        map((response) =>
          GetOrdersIdCheckGeometryComplexityResponseDTO.parse(response),
        ),
      );
  }

  updateConcernLevel(
    reportId: string,
    reportPartName: string,
    concernLevel: ReportUpdateConcernLevelBodyDTO,
  ): Observable<ReportUpdatedDTO> {
    return this.http.patch<ReportUpdatedDTO>(
      `${environment.apiUrl}/reports/${reportId}/concern-level/${reportPartName}`,
      ReportUpdateConcernLevelBodyDTO.parse(concernLevel),
    );
  }

  updateDrawnObjects(
    reportId: string,
    drawnObjects: GeometryObject | null,
  ): Observable<ReportUpdatedDTO> {
    return this.http
      .patch<ReportUpdatedDTO>(
        `${environment.apiUrl}/reports/${reportId}/drawn-objects`,
        {
          drawn_objects: drawnObjects,
        },
      )
      .pipe(map((response) => ReportUpdatedDTO.parse(response)));
  }

  deleteReportById(reportId: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/reports/${reportId}`);
  }

  hasReportParts(
    report: {
      report_parts?: {
        type?: string;
        status?: string;
      }[];
    },
    types: string[],
  ): boolean {
    const hasParts = types
      .map(
        (type) =>
          report.report_parts?.find((part) => part.type === type)?.status ===
          'complete',
      )
      .reduce((prev, curr) => prev && curr, true);

    return hasParts;
  }

  getConcernLevelIcon(concernLevel: string): string {
    switch (concernLevel) {
      case 'high':
        return 'fa-exclamation-circle';
      case 'moderate':
        return 'fa-exclamation';
      case 'low':
        return 'fa-check';
      case 'unknown':
        return 'fa-question-circle-o';
      default:
        return 'fa-question-circle-o';
    }
  }

  getReportVersion(reportId: string): Observable<{ version: string }> {
    return this.http.get<{ version: string }>(
      `${environment.apiUrl}/reports/${reportId}/version`,
    );
  }

  focusReportMap(): void {
    this._focusReportMap.next(true);
  }

  private fetchReportWithAnnotations(
    reportId: string,
    loadAnnotations?: boolean,
  ): Observable<ReportShowDTO> {
    const report$ = this.http
      .get<ReportShowDTO>(`${environment.apiUrl}/reports/${reportId}`)
      .pipe(map((response) => ReportShowDTO.parse(response)));

    if (!loadAnnotations) {
      return report$;
    }

    return this.http
      .get<number>(`${environment.apiUrl}/report-annotations/${reportId}/count`)
      .pipe(
        switchMap((annotationsCount) => {
          let page = 1;
          const pageSize = 100;
          const annotations$: Observable<
            ReportShowDTO['report_annotations'] | null
          >[] = [of(null)];
          while (annotationsCount > 0) {
            annotations$.push(
              this.http
                .get<ReportShowDTO['report_annotations'] | null>(
                  `${environment.apiUrl}/report-annotations/reports/${reportId}?page=${page}&pageSize=${pageSize}`,
                )
                .pipe(
                  catchError((error) => {
                    console.error(error);
                    return of(null);
                  }),
                ),
            );

            page++;
            annotationsCount -= pageSize;
          }
          return forkJoin([report$, forkJoin(annotations$)]);
        }),
        map(([report, annotations]) => {
          report.report_annotations = annotations
            .filter(
              (a): a is NonNullable<ReportShowDTO['report_annotations']> =>
                Boolean(a),
            )
            .flatMap((annotation) => annotation);
          return report;
        }),
        takeUntil(this.destroy$),
      );
  }

  saveExpertNotes(reportId: string, exportNotes: string) {
    const requestBody = {
      notes: exportNotes,
    };
    return this.http.patch<Report>(
      `${environment.apiUrl}/reports/${reportId}/expert-notes`,
      requestBody,
    );
  }

  saveRefreshDate(reportId: string) {
    return this.http.patch<Report>(
      `${environment.apiUrl}/reports/${reportId}/refresh-date`,
      null,
    );
  }
}
