import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FormTypeDTO,
  GetFormTypeLatestVersionResponse,
} from '@transect-nx/data-transfer-objects';
import { FormTypeKey } from '@transect-nx/models';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormTypesService {
  private readonly baseEndPoint = `${environment.apiUrl}/form-types`;

  constructor(private http: HttpClient) {}

  getFormTypeByKey(key: FormTypeDTO['key']): Observable<FormTypeDTO> {
    return this.http
      .get<FormTypeDTO>(`${this.baseEndPoint}/${key}`)
      .pipe(map((response) => FormTypeDTO.parse(response)));
  }

  getFormTypeLatestVersion(key: FormTypeKey) {
    return this.http
      .get<unknown>(`${this.baseEndPoint}/${key}/latest-version`)
      .pipe(
        map((response) => GetFormTypeLatestVersionResponse.parse(response)),
      );
  }
}
