import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { OldGeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const ProtectedAreaProperties = z.object({
  access: z.string(),
  des_tp: z.string(),
  loc_ds: z.string(),
  loc_nm: z.string(),
  agg_src: z.string(),
  gap_sts: z.string(),
  gapcddt: z.string(),
  gis_src: z.string(),
  loc_own: z.string(),
  unit_nm: z.string(),
  wdpa_cd: z.number().nullable(),
  category: z.string(),
  comments: z.string(),
  date_est: z.string(),
  eholdtyp: z.string(),
  esmthldr: z.string(),
  gapcdsrc: z.string(),
  iucn_cat: z.string(),
  iucnctdt: z.string(),
  loc_mang: z.string(),
  objectid: z.number(),
  own_name: z.string(),
  own_type: z.string(),
  src_date: z.string(),
  state_nm: z.string(),
  access_dt: z.string(),
  featclass: z.string(),
  gis_acres: z.number(),
  iucnctsrc: z.string(),
  mang_name: z.string(),
  mang_type: z.string(),
  access_src: z.string(),
  shape_area: z.number(),
  source_paid: z.string(),
  shape_length: z.number(),
});

const ProtectedArea = z.object({
  _id: z.string().uuid(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  properties: ProtectedAreaProperties.deepPartial(),
  geometry: OldGeometrySchema,
  source__id: z.string().uuid(),
  transect_feature_type: z.literal('protected_area'),
  transect_summary: TransectSummarySchema,
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
});

export const ReportPartProtectedAreaDTO = ProtectedArea;

export type ReportPartProtectedAreaDTO = z.infer<
  typeof ReportPartProtectedAreaDTO
>;
