import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetReportAddOnsIdInvoiceDetailsResponseDTO } from '@transect-nx/data-transfer-objects';
import { DisplayedReportAddOn, ReportAddOnStatus } from '@transect-nx/models';
import moment from 'moment';
import { map, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ReportAddOn } from '../../models/report-add-on';
import { ResponseRows } from '../../models/response-rows';

@Injectable({
  providedIn: 'root',
})
export class ReportAddOnApiService {
  private readonly apiUrl = `${environment.apiUrl}/report-add-ons`;

  constructor(private http: HttpClient) {}

  getReportAddOn(id: string) {
    return this.http.get<ReportAddOn>(`${this.apiUrl}/${id}`);
  }

  getMyMarketPlaceOrders(params?: {
    search?: string;
    page?: number;
    pageSize?: number;
    sortModel?: { colId: string; sort: 'asc' | 'desc' | undefined | null }[];
  }) {
    return this.http.get<ResponseRows<ReportAddOn>>(
      `${this.apiUrl}/my-marketplace-orders/`,
      {
        params: {
          search: params?.search ?? '',
          page: params?.page?.toString() ?? '',
          pageSize: params?.pageSize?.toString() ?? '',
          sortModel: JSON.stringify(params?.sortModel) ?? '',
        },
      },
    );
  }

  getReportAddOns(
    reportId: string,
    reportAddOnOfferingKey?: 'local_rrs',
    status?: ReportAddOnStatus,
    omitCancelledAndRejected?: boolean,
  ) {
    return this.http
      .get<ResponseRows<DisplayedReportAddOn>>(`${this.apiUrl}`, {
        params: {
          reportId,
          ...(reportAddOnOfferingKey && { reportAddOnOfferingKey }),
          ...(status && { status }),
          omitCancelledAndRejected: omitCancelledAndRejected ?? false,
        },
      })
      .pipe(
        map((response) => {
          return {
            ...response,
            rows: response.rows.map((row) => {
              if (row?.report_add_on_offering?.sla) {
                row.report_add_on_offering.sla = moment.duration(
                  row.report_add_on_offering.sla,
                );
              }

              return row;
            }),
          };
        }),
      );
  }

  update(
    id: string | null | undefined,
    data: Partial<ReportAddOn>,
  ): Observable<ReportAddOn> {
    if (!id) {
      throw new Error('Missing "id"!');
    }
    return this.http.put<ReportAddOn>(`${this.apiUrl}/${id}`, data);
  }

  resetDueDateChanged(updatedIds: string[]): Observable<ReportAddOn[]> {
    if (!updatedIds.length) {
      return of<ReportAddOn[]>([]);
    }
    return this.http.patch<ReportAddOn[]>(
      `${this.apiUrl}/reset-due-date-changed`,
      { ids: updatedIds },
    );
  }

  associateFormsWithAddOn(addOnId: string, formIds: string[]) {
    return this.http.post(`${this.apiUrl}/${addOnId}/forms`, {
      formIds,
    });
  }

  fetchInvoiceDetails(addOnId: string) {
    return this.http
      .get<GetReportAddOnsIdInvoiceDetailsResponseDTO>(
        `${this.apiUrl}/${addOnId}/invoice-details`,
      )
      .pipe(
        map((response) => {
          return GetReportAddOnsIdInvoiceDetailsResponseDTO.parse(response);
        }),
      );
  }
}
