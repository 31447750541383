import { Injectable } from '@angular/core';
import { TransectStateService } from '../../../utils/state-management';
import { RulerUnit } from '../../ruler-unit/ruler-unit.component';
export type RulerUnitState = {
  unit: RulerUnit;
};

@Injectable()
export class RulerUnitStateService extends TransectStateService<RulerUnitState> {
  unit$ = this.select((state) => state.unit);
  constructor() {
    super({
      unit: 'miles',
    });
  }

  dispatchSetUnitAction(unit: RulerUnit) {
    this.updateState({ unit });
  }

  registerEffects(): void {
    throw new Error('Method not implemented.');
  }
  unRegisterEffects(): void {
    throw new Error('Method not implemented.');
  }
}
