<div class="map-container" #mapContainer [class.wait]="showWaitCursor">
  <div class="map-controls">
    <button
      aria-label="go to area of interest"
      (click)="goToAoi()"
      class="control-btn mapboxgl-ctrl mapboxgl-ctrl-group"
      type="button"
      title="Zoom to AOI"
    >
      <i class="fas fa-bullseye"></i>
    </button>
  </div>
</div>
