import { InjectionToken } from '@angular/core';
import { versionInfo } from '@transect-nx/shared/version';
import Rollbar from 'rollbar';
import { environment } from '../../environments/environment';

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory() {
  const rollbarConfig = {
    async: true,
    accessToken: environment.ROLLBAR_PUBLISHABLE_KEY,
    addErrorContext: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    verbose: true,
    environment: environment.ROLLBAR_ENVIRONMENT || 'local',
    checkIgnore: function (isUncaught, args, payload) {
      return (
        typeof payload?.custom === 'object' &&
        payload?.custom &&
        'code' in payload?.custom &&
        payload?.custom?.code === 'TokenExpiredError'
      );
    },
    payload: {
      environment: environment.ROLLBAR_ENVIRONMENT || 'local',
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: versionInfo.hash,
          guess_uncaught_frames: true,
        },
      },
      server: {
        root: 'webpack:///./',
      },
    },
  } as Rollbar.Configuration;
  // eslint-disable-next-line no-restricted-syntax
  console.debug('Rollbar service config: ', rollbarConfig);
  return new Rollbar(rollbarConfig);
}
