import { z } from 'zod';

const KeyEnumV1 = z.enum([
  'aoi',
  'aspect',
  'buffer',
  'catalog_location',
  'contour',
  'developable_area',
  'ecoregion',
  'environmental_compliance',
  'farmland_classification',
  'firm_panel',
  'flood_hazard',
  'geography',
  'geology',
  'gw',
  'hydrography',
  'landcover',
  'parcel',
  'protected_area',
  'regulation_location',
  'slope',
  'slope2',
  'soil',
  'soil_hydric_rating',
  'soil_bedrock_depth_min',
  'soil_hydraulic_group',
  'species_location',
  'state_species_location',
  'transportation',
  'watershed',
  'wetland',
  'disputed_wetland',
  'wotus',
]);

type KeyEnumV1 = z.infer<typeof KeyEnumV1>;

export const PaintV1 = z.object({
  fill: z.optional(z.any()),
  line: z.optional(z.any()),
  circle: z.optional(z.any()),
  symbol: z.optional(z.any()),
});

export type PaintV1 = z.infer<typeof PaintV1>;

export const ChildConfigurationV1 = z.object({
  // The child keys are generated from properties, so it's not
  // a finite set we can use an enum for.
  key: z.optional(z.string()),
  index: z.number(),
  paint: PaintV1,
});

export type ChildConfigurationV1 = z.infer<typeof ChildConfigurationV1>;

export const ConfigurationV1 = z.object({
  key: KeyEnumV1,
  index: z.number(),
  paint: PaintV1,
  children: z.array(ChildConfigurationV1),
});

export type ConfigurationV1 = z.infer<typeof ConfigurationV1>;

export const ReportExportKmlOptionsV1 = z.object({
  version: z.literal(1),
  configuration: z.array(ConfigurationV1),
});

export type ReportExportKmlOptionsV1 = z.infer<typeof ReportExportKmlOptionsV1>;

export const LandCoverNameLookup: { [property: string]: string } = {
  '11': 'Open Water',
  '12': 'Perennial Ice/Snow',
  '21': 'Developed, Open Space',
  '22': 'Developed, Low Intensity',
  '23': 'Developed, Medium Intensity',
  '24': 'Developed High Intensity',
  '31': 'Barren Land (Rock/Sand/Clay)',
  '41': 'Deciduous Forest',
  '42': 'Evergreen Forest',
  '43': 'Mixed Forest',
  '51': 'Dwarf Scrub',
  '52': 'Shrub/Scrub',
  '71': 'Grassland/Herbaceous',
  '72': 'Sedge/Herbaceous',
  '73': 'Lichens',
  '74': 'Moss',
  '81': 'Pasture/Hay',
  '82': 'Cultivated Crops',
  '90': 'Woody Wetlands',
  '95': 'Emergent Herbaceous Wetlands',
};

export const SlopeCodeNameLookup: { [property: string]: string } = {
  '1': '0-5%',
  '2': '5-10%',
  '3': '10-15%',
  '4': '>15%',
};

export const Slope2CodeNameLookup: { [property: string]: string } = {
  '1': '0-2%',
  '2': '2-4%',
  '3': '4-6%',
  '4': '6-8%',
  '5': '8-10%',
  '6': '10-12%',
  '7': '12-14%',
  '8': '14-16%',
  '9': '16-18%',
  '10': '18-20%',
  '11': '20-22%',
  '12': '22-24%',
  '13': '>24%',
};

export const AspectLookup: { [property: string]: string } = {
  '0': 'North (338° - 22°)',
  '1': 'Northeast (23° - 67°)',
  '2': 'East (68° - 112°)',
  '3': 'Southeast (113° - 157°)',
  '4': 'South (158° - 202°)',
  '5': 'Southwest (203° - 247°)',
  '6': 'West (248° - 292°)',
  '7': 'Northwest (293° - 337°)',
};
