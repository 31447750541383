import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { OldGeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const EcoregionProperties = z.object({
  gid: z.number(),
  l1_key: z.string(),
  l2_key: z.string(),
  l3_key: z.string(),
  l4_key: z.string(),
  na_l1code: z.string(),
  na_l1name: z.string(),
  na_l2code: z.string(),
  na_l2name: z.string(),
  na_l3code: z.string(),
  na_l3name: z.string(),
  us_l3code: z.string(),
  us_l3name: z.string(),
  us_l4code: z.string(),
  us_l4name: z.string(),
  shape_area: z.number(),
  shape_leng: z.number(),
});

export const ReportPartEcoregionFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  properties: EcoregionProperties.deepPartial(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  l4_code: z.string().nullable(),
  has_intersection_aoi: z.boolean(),
  has_intersection_buffer: z.boolean(),
  geometry: OldGeometrySchema,
  source__id: z.string().uuid(),
  transect_feature_type: z.literal('ecoregion'),
  transect_summary: TransectSummarySchema,
});

export type ReportPartEcoregionFeatureDTO = z.infer<
  typeof ReportPartEcoregionFeatureDTO
>;
