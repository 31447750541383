<div
  class="window-container"
  tsDraggableWindow
  [draggingEnabled]="isDraggable"
  [ngStyle]="{
    width: width + 'px',
    top: position.top,
    left: position.left,
    right: position.right,
    bottom: position.bottom,
    position: isPositionAbsolute ? 'absolute' : 'static',
    height:
      isContentExpanded | mapControlWindowHeight : isDynamicHeight : minimized
  }"
  [class.warning-border]="showWarning"
>
  <div
    class="map-window-container"
    [class.dynamic-height-flex-column]="isDynamicHeight"
    [class.border-radius-sm]="isSmallBorderRadius"
    [ngStyle]="
      isDynamicHeight && !minimized
        ? {
            height: 'inherit'
          }
        : {}
    "
  >
    <div
      *ngIf="header"
      class="map-window-header"
      [class.draggable-handle]="isDraggable"
    >
      <transect-nx-toggle-button
        *ngIf="shouldDisplayToggleBtn"
        [customColors]="{ on: 'white', off: 'white' }"
        buttonStyle="flat"
        (change)="toggleWindow()"
        size="xs"
      >
        <i class="fas fa-minus-circle" *ngIf="!minimized"></i>
        <i class="fas fa-plus-circle" *ngIf="minimized"></i>
      </transect-nx-toggle-button>

      <div
        *ngIf="headerActionsWidth > 0 && headerAlign === 'center'"
        [ngStyle]="{ width: headerActionsWidth + 'px' }"
      ></div>

      <div [ngStyle]="{ 'text-align': headerAlign }" class="header-label">
        {{ header }}
      </div>

      <div #headerActions class="header-actions">
        <ng-content select="[headerActions]"></ng-content>
      </div>
    </div>
    <div
      class="map-window-content"
      [ngStyle]="{
        height: minimized ? '0' : isDynamicHeight ? 'inherit' : height + 'px'
      }"
      [class.minimized]="minimized"
      [class.animate]="!isDynamicHeight"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
