import { z } from 'zod';
import { FormTypeDTO } from '../form-type';
import { ReportAddOnOfferingKey as ReportAddOnOfferingKeyBase } from '@transect-nx/models';

export const ReportAddOnOfferingKey = z.nativeEnum(ReportAddOnOfferingKeyBase);

export type ReportAddOnOfferingKey = z.infer<typeof ReportAddOnOfferingKey>;

export const ReportAddOnOfferingType = z.enum(['purchase', 'quote']);

export type ReportAddOnOfferingType = z.infer<typeof ReportAddOnOfferingType>;

export const ReportAddOnOfferingDTO = z
  .object({
    _id: z.string().uuid(),
    name: z.string(),
    key: z.nativeEnum(ReportAddOnOfferingKeyBase),
    description: z.string().nullish(),
    type: ReportAddOnOfferingType,
    why_do_i_need_this: z.string().nullish(),
    image_url: z.string().nullish(),
    deliverable_preview_link: z.string().nullish(),
    deliverable_image_url: z.string().nullish(),
    delivery_description_html: z.string().nullish(),
    details_description_html: z.string().nullish(),
    icon_class: z.string(),
    icon_image_url: z.string(),
    report_required: z.boolean(),
    quote_sla_description: z.string().nullish(),
    required_form_types: z.array(FormTypeDTO).nullish(),
    in_app_marketplace_enabled: z.boolean().nullish(),
    public_marketplace_enabled: z.boolean().nullish(),
  })
  .passthrough();

export type ReportAddOnOfferingDTO = z.infer<typeof ReportAddOnOfferingDTO>;

export const ReportAddOnOfferingKeyToNameMap = {
  pzr: 'Planning and Zoning Report',
  slope: 'Slope & Aspect Calculation',
  archaeology: 'SHPO Records Search',
  local_rrs: 'Local Environmental Regulations',
  phase1_esa: 'Phase 1 ESA',
  tess: 'Threatened and Endangered Species Survey',
  wetland_dln: 'Wetlands Delineation',
  alta: 'Land Title Field Survey',
  interconnection_study: 'Initial Interconnection Feasibility Assessment',
  cec_tigercomm: 'Community Engagement Consulting',
} as const;
