import { z } from 'zod';
import { OldGeometrySchema } from '../../utils/geometry-object-schema';

export const ImportedLocationDTO = z.object({
  _id: z.string().uuid(),
  properties: z
    .record(
      z
        .union([
          z.number(),
          z.string(),
          z.boolean(),
          z.object({}).passthrough(),
        ])
        .nullable(),
    )
    .optional(),
  import__id: z.string().uuid().nullable(),
  geometry: OldGeometrySchema.nullable(),
});

export type ImportedLocationDTO = z.infer<typeof ImportedLocationDTO>;
