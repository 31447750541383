import { z } from 'zod';
import { OldGeometrySchema } from '../../../utils/geometry-object-schema';

export const PatchReportDrawnObjectsBodyDTO = z.object({
  drawn_objects: OldGeometrySchema.nullish(),
});

export type PatchReportDrawnObjectsBodyDTO = z.infer<
  typeof PatchReportDrawnObjectsBodyDTO
>;
