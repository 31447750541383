import { z } from 'zod';
import { OldGeometrySchema } from '../../../utils/geometry-object-schema';
import { SpeciesObject } from '../species-object';

export const SpeciesGeneratePreviewDTO = SpeciesObject.extend({
  preview: OldGeometrySchema.nullable(),
  bbox: OldGeometrySchema.nullable(),
});

export type SpeciesGeneratePreviewDTO = z.infer<
  typeof SpeciesGeneratePreviewDTO
>;
