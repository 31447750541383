import { z } from 'zod';
import { Sentiment } from '../../utils';
import { PulseTechnology } from '@transect-nx/models';

export const SolarPulseCountyDTO = z.object({
  name: z.string(),
  id: z.string().uuid().nullish(),
  type: z.string(),
  sentiment_score: z.number(),
  sentiment: Sentiment,
  solar_regulations_score: z.number(),
  solar_regulations_sentiment: Sentiment,
  solar_news_score: z.number(),
  solar_news_sentiment: Sentiment,
  solar_velocity_score: z.number(),
  solar_velocity_sentiment: Sentiment,
  solar_electric_generator_count: z.number().default(0).optional(),
  solar_regulations_count: z.number().default(0).optional(),
  solar_sentiment_count: z.number().default(0).optional(),
  solar_contested_farms_count: z.number().default(0).optional(),
});

export const SolarPulseStateDTO = z.array(
  z.object({
    state: z.string(),
    state_abbreviation: z.string(),
    geography__id: z.string(),
    counties: z.array(SolarPulseCountyDTO),
  }),
);

export type SolarPulseStateDTO = z.infer<typeof SolarPulseStateDTO>;

const GeographyPulseDTO = z.object({
  _id: z.string().uuid(),
  type: z.string(),
  name: z.string(),
  technology: z.nativeEnum(PulseTechnology),
  sentiment_score: z.number(),
  sentiment: Sentiment,
  regulations_count: z.number(),
  regulations_score: z.number(),
  regulations_sentiment: Sentiment,
  news_count: z.number(),
  news_score: z.number(),
  news_sentiment: Sentiment,
  electric_generator_count: z.number(),
  contested_farms_count: z.number(),
  velocity_score: z.number(),
  velocity_sentiment: Sentiment,
});

export const GeographiesPulseDTO = z.array(GeographyPulseDTO);
export type GeographiesPulseDTO = z.infer<typeof GeographiesPulseDTO>;
