import { SharedModule } from '@transect/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapControlWindowComponent } from './components/map-control-window/map-control-window.component';
import { MapControlAccordionComponent } from './components/map-control-accordion/map-control-accordion.component';
import { TransectButtonsModule } from '@transect-nx/ui';
import { MapControlWindowHeightPipe } from './components/map-control-window/map-control-window-height.pipe';

@NgModule({
  declarations: [
    MapControlWindowComponent,
    MapControlAccordionComponent,
    MapControlWindowHeightPipe,
  ],
  imports: [CommonModule, TransectButtonsModule, FormsModule, SharedModule],
  exports: [MapControlWindowComponent, MapControlAccordionComponent],
})
export class MapControlWindowModule {}
