import { PulseRegulationsTechnology } from '@transect-nx/models';
import { z } from 'zod';
import { Sentiment } from '../../utils';
import { DateSchema } from '../../utils/date-schema';
import { OldGeometrySchema } from '../../utils/geometry-object-schema';
import { GeographyDTO } from '../geography';
import { UserDTO } from '../users';
import { SolarRegulationType, VerificationStatus } from '@transect-nx/models';

export const SolarRegulationDTO = z.object({
  _id: z.string().uuid(),
  deleted_at: DateSchema.nullable(),
  name: z.string(),
  type: z.string(),
  state: z.string().nullable(),
  county: z.string().nullable(),
  id: z.string().nullable(),
  summary: z.string().nullable(),
  date_term: z.string().nullable(),
  extract_date: DateSchema.nullable(),
  document_title: z.string().nullable(),
  content_original_link: z.string().nullable(),
  geometry: OldGeometrySchema.nullable(),
  regulation_type: z.nativeEnum(SolarRegulationType),
  verification_request_notes: z.string().nullable(),
  verification_request_date: DateSchema.nullable(),
  verification_requested_by: UserDTO.partial().nullish(),
  verification_notes: z.string().nullable(),
  verification_status: z.nativeEnum(VerificationStatus),
  verification_complete_date: DateSchema.nullable(),
  verification_assignee: UserDTO.partial().nullable(),
  expiration_date: DateSchema.nullish(),
  geography: GeographyDTO.deepPartial().nullish(),
  technology: z.nativeEnum(PulseRegulationsTechnology).nullable(),
});
export type SolarRegulationDTO = z.infer<typeof SolarRegulationDTO>;

export const SolarRegulationItemDTO = SolarRegulationDTO.omit({
  geometry: true,
  verification_requested_by: true,
  verification_notes: true,
  verification_assignee: true,
  deleted_at: true,
});

export type SolarRegulationItemDTO = z.infer<typeof SolarRegulationItemDTO>;

export const SolarRegulationsResponseDTO = z.object({
  regulations: z.array(SolarRegulationItemDTO),
  sentiment: Sentiment.nullish(),
});

export type SolarRegulationsResponseDTO = z.infer<
  typeof SolarRegulationsResponseDTO
>;
