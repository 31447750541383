import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TransectStateService } from '../../../utils/state-management';
import { FlyoverComponentTemplate } from '@transect-nx/ui';
import { map, take, tap } from 'rxjs/operators';

type State = {
  isOpen: boolean;
  template: FlyoverComponentTemplate | null;
  shouldCloseOnBackdropClick: boolean;
  minWidth?: string;
  maxWidth?: string;
  closeReturnValue?: unknown;
};

@Injectable()
export class AppFlyoverStateService extends TransectStateService<State> {
  private readonly closeAction = new Subject<void>();

  isOpen$ = this.select((state) => state.isOpen);

  minWidth$ = this.select((state) => state.minWidth);

  maxWidth$ = this.select((state) => state.maxWidth);

  shouldCloseOnBackdropClick$ = this.select(
    (state) => state.shouldCloseOnBackdropClick,
  );

  template$ = this.select((state) => state.template);

  /**
   * this is not state, this is just a way to expose an action to other components so that they can react to it.
   it will be fired when the backdrop is clicked, or an explicit back button is clicked
   */
  onClose$ = this.closeAction.asObservable().pipe(
    map(() => this.stateValue.closeReturnValue),
    tap(() => this.updateState({ closeReturnValue: undefined })),
  );

  constructor() {
    super({
      isOpen: false,
      template: null,
      shouldCloseOnBackdropClick: true,
    });
  }

  registerEffects(): void {
    throw new Error('Method not implemented.');
  }

  unRegisterEffects(): void {
    throw new Error('Method not implemented.');
  }

  dispatchOpenFlyover<T = unknown, I extends object = object, ReturnVal = void>(
    template: FlyoverComponentTemplate<T, I>,
    minWidth?: string,
    maxWidth?: string,
    shouldCloseOnBackdropClick = true,
  ) {
    this.updateState({
      isOpen: true,
      minWidth,
      maxWidth,
      template,
      shouldCloseOnBackdropClick,
    });

    return {
      onClose$: this.onClose$.pipe(take(1)) as unknown as Observable<ReturnVal>,
    };
  }

  dispatchCloseFlyoverReturnValueAction<ReturnVal>(returnVal: ReturnVal) {
    this.updateState({
      closeReturnValue: returnVal,
    });
  }

  dispatchCloseFlyoverAction() {
    this.updateState({
      isOpen: false,
      template: null,
    });
    this.closeAction.next();
  }

  dispatchSetMinWidth(minWidth: string) {
    this.updateState({ minWidth });
  }
}
