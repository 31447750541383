import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { OldGeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

export const ReportPartLandcoverFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  code: z.string().nullable(),
  geometry: OldGeometrySchema,
  source__id: z.string().uuid(),
  transect_feature_type: z.literal('landcover'),
  transect_summary: TransectSummarySchema,
});

export type ReportPartLandcoverFeatureDTO = z.infer<
  typeof ReportPartLandcoverFeatureDTO
>;
