import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GeometriesOrCollectionSchema,
  GetProblematicLCIParcelCountResponseDTO,
  GetProjectsIdSiteAccessInfoResponseDTO,
  GetProjectsMineResponseDTO,
  GetProjectsMultipleWithGeometryQueryDTO,
  LandownerContactWithParcelFilterParcelsResponseDTO,
  ProjectInfoQueryDTO,
  ProjectUpsellInformationDTO,
  ProjectUserDTO,
  SiteSelectionInfoResponseDTO,
  VisionProjectGeometryDTO,
  VisionProjectsMultipleWithGeometryDTO,
} from '@transect-nx/data-transfer-objects';
import {
  ProjectsScope,
  ProjectStatus,
  TransectFile,
} from '@transect-nx/models';
import * as turf from '@turf/turf';
import {
  Feature,
  FeatureCollection,
  Geometries,
  GeometryCollection,
} from '@turf/turf';
import { ColumnVO } from 'ag-grid-community';
import _map from 'lodash/map';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiUrls } from '../models/api-urls';
import { Project, ProjectContext, ProjectPreferences } from '../models/project';
import { ProjectAccess } from '../models/project-access';
import { Report } from '../models/report';
import { ResponseRows } from '../models/response-rows';
import { VisionProject } from '../models/vision-project';
import {
  IVisionProjectViewEntity,
  IVisionProjectViewResponse,
} from '../models/vision-project-view';
import { GridColumnFilterModel } from '../modules/transect-virtual-table/models/grid-column-filter.model';
import { AuthService } from './auth.service';
import { ProjectRole } from './permissions/permission.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private readonly baseEndPoint: string = `${environment.apiUrl}/projects`;
  private readonly newProjectGeometryKey = 'new_project_geometry';

  constructor(private http: HttpClient, private authService: AuthService) {}

  patchProjectContext(projectId: string, context: ProjectContext) {
    return this.http.patch(
      `${environment.apiUrl}/projects/${projectId}/context`,
      context,
    );
  }

  getSiteAccessInfo(projectId: string) {
    return this.http
      .get<GetProjectsIdSiteAccessInfoResponseDTO>(
        `${this.baseEndPoint}/${projectId}/site-access-info`,
      )
      .pipe(
        map((response) => {
          return GetProjectsIdSiteAccessInfoResponseDTO.parse(response);
        }),
      );
  }

  setNewProjectGeometry(featureCollection: FeatureCollection): void {
    const geometryCollection = turf.geometryCollection(
      featureCollection.features.map(
        (feature) => feature.geometry,
      ) as Geometries[],
    );

    localStorage.setItem(
      this.newProjectGeometryKey,
      JSON.stringify(geometryCollection),
    );
  }

  getNewProjectGeometry(clear = false): Feature<GeometryCollection> | null {
    const geometryString = localStorage.getItem(this.newProjectGeometryKey);

    if (geometryString) {
      if (clear) {
        localStorage.removeItem(this.newProjectGeometryKey);
      }

      return JSON.parse(geometryString) as Feature<GeometryCollection>;
    }

    return null;
  }

  getMyProjects(params?: {
    search?: string;
    page?: number;
    pageSize?: number;
    sortModel?: { column: string; order: 'asc' | 'desc' | undefined | null }[];
    projectIds?: string[];
    projectsScope?: ProjectsScope;
    filterModel?: GridColumnFilterModel;
  }): Observable<GetProjectsMineResponseDTO> {
    let httpParams = new HttpParams({
      fromObject: {
        search: params?.search ?? '',
        page: params?.page?.toString() ?? '',
        pageSize: params?.pageSize?.toString() ?? '',
        sortModel: JSON.stringify(params?.sortModel) ?? '',
        projectIds: JSON.stringify(params?.projectIds) ?? '',
        filterModel: JSON.stringify(params?.filterModel) ?? '',
      },
    });

    if (params?.projectsScope !== undefined) {
      httpParams = httpParams.append('projectsScope', params.projectsScope);
    }

    return this.http.get<GetProjectsMineResponseDTO>(
      environment.apiUrl + '/projects/mine',
      {
        params: httpParams,
      },
    );
  }

  getMyProjectStates(): Observable<{ state: string }[]> {
    return this.http.get<{ state: string }[]>(
      environment.apiUrl + '/projects/mine/states',
    );
  }

  getMyProjectCounties(): Observable<{ county: string }[]> {
    return this.http.get<{ county: string }[]>(
      environment.apiUrl + '/projects/mine/counties',
    );
  }

  getMyProjectReportStatuses(): Observable<{ status: string }[]> {
    return this.http.get<{ status: string }[]>(
      environment.apiUrl + '/projects/mine/report-statuses',
    );
  }

  getProjectReports(projectId: string): Observable<ResponseRows<Report>> {
    return this.http.get<ResponseRows<Report>>(
      `${this.baseEndPoint}/${projectId}/reports`,
    );
  }

  getUpsellInformation(
    projectId: string,
  ): Observable<ProjectUpsellInformationDTO> {
    return this.http
      .get<ProjectUpsellInformationDTO>(
        `${this.baseEndPoint}/${projectId}/upsell-information`,
      )
      .pipe(
        map((response) => {
          return ProjectUpsellInformationDTO.parse(response);
        }),
      );
  }

  getTheirProjects(userId: string): Observable<ResponseRows<Project>> {
    return this.http.get<ResponseRows<Project>>(`${this.baseEndPoint}/theirs`, {
      params: {
        user__id: userId,
        projectsScope: ProjectsScope.MY_PROJECTS,
      },
    });
  }

  updateProjectPreferences(
    projectId: string,
    preferences: { visionColor: string },
  ): Observable<ProjectAccess> {
    return this.http.put<Project>(
      `${this.baseEndPoint}/update-user-preferences/${projectId}`,
      preferences,
    );
  }

  getFiles(projectId: string): Observable<ResponseRows<TransectFile>> {
    return this.http.get<ResponseRows<TransectFile>>(
      `${environment.apiUrl}/files`,
      {
        params: {
          project__id: projectId,
        },
      },
    );
  }

  getUsers(projectId: string): Observable<ProjectUserDTO[]> {
    return this.http
      .get<ProjectUserDTO[]>(
        `${environment.apiUrl}/projects/${projectId}/users`,
      )
      .pipe(map((users) => users.map((user) => ProjectUserDTO.parse(user))));
  }

  saveUsers(
    projectId: string,
    users: { user__id: string; role: ProjectRole }[],
  ): Observable<void> {
    return this.http.post<void>(
      `${this.baseEndPoint}/${projectId}/users`,
      users,
    );
  }

  deleteProjectById(projectId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseEndPoint}/${projectId}`);
  }

  deleteProjectsByIds(projectIds: string[]): Observable<void> {
    return this.http.post<void>(`${this.baseEndPoint}/bulk-delete`, {
      projectIds,
    });
  }

  getProject(id: string): Observable<Project> {
    return this.http.get<Project>(`${this.baseEndPoint}/${id}`);
  }

  getProjectAddOnsCount(id: string): Observable<number> {
    if (!id) {
      throw new Error('Project ID is undefined, perhaps it is a mini-report.');
    }
    return this.http.get<number>(
      `${this.baseEndPoint}/${id}/projectAddOnsCount`,
    );
  }

  createProject(params: {
    name: string;
    createdFrom:
      | 'vision'
      | 'projects'
      | 'report'
      | 'parcel_filter'
      | 'ss_single_export'
      | 'bulk_projects';
    forClient?: boolean;
    customerId?: string;
    creatorId?: string;
    description?: string;
    clientIdentifier?: string;
    preferences?: ProjectPreferences;
    context?: {
      construction_type?: string | null;
      expert_review?: string | null;
      federal_funds?: string | null;
      plan_to_impact_waters?: string | null;
      project_type?: string | null;
      project_subtype?: string | null;
      mixed_use_subtype?: string[] | null;
      buffer_width_feet?: number | null;
      buildable_area_layer_template_id?: string | null;
      pdf_export_template_id?: string | null;
      pdf_export_name?: string | null;
    };
    geometry?: any;
    fromNdaId?: string;
    cleanup_weekly?: boolean;
    parcel_filter_id?: string;
    properties?: { fileName?: string };
  }): Observable<Project> {
    return this.http
      .post<Project>(`${this.baseEndPoint}`, {
        name: params.name,
        for_a_client: params.forClient ?? false,
        created_from: params.createdFrom,
        customer__id: params.customerId,
        creator__id: params.creatorId,
        description: params.description,
        client_identifier: params.clientIdentifier,
        context: params.context,
        from_nda_id: params.fromNdaId,
        cleanup_weekly: params.cleanup_weekly,
        geometry: params.geometry as GeoJSON.Geometry,
        parcel_filter_id: params.parcel_filter_id,
        ...(params.preferences && { preferences: params.preferences }),
        properties: params.properties ?? {},
      })
      .pipe(
        tap(() => {
          this.authService.incrementUserCreatedProjectsCount();
        }),
      );
  }

  /**
   * Updates an existing project.
   * The project geometry will be overridden by a generated parcel geometry if
   * the {@link options:parcelFilterId} is included.
   * @param project
   * @param options
   * @returns
   */
  saveProject(
    project: Project,
    options?: {
      parcelFilterId?: string;
    },
  ): Observable<Project & { _id: string }> {
    if (!project._id) {
      throw new Error('Cannot save a project without the _id.');
    }

    return this.http.put<Project & { _id: string }>(
      `${this.baseEndPoint}/${project._id}`,
      {
        ...project,
        parcel_filter_id: options?.parcelFilterId,
      },
    );
  }

  cloneProject(
    projectId: string,
    name?: string,
    geometry?: GeometriesOrCollectionSchema,
  ): Observable<Project> {
    return this.http.post<Project>(`${this.baseEndPoint}/clone`, {
      projectId,
      name,
      geometry,
    });
  }

  fetchCount(opts: {
    creatorId: string | undefined;
    createdFrom: 'vision' | 'projects' | 'admin' | 'bulk_projects';
  }): Observable<number> {
    const params = {
      ...(opts.creatorId ? { creatorId: opts.creatorId } : {}),
      createdFrom: opts.createdFrom,
    };
    return this.http
      .get<{ count: number }>(`${this.baseEndPoint}/count`, { params })
      .pipe(map(({ count }) => count));
  }

  deleteVisionProjectById(id: string): Observable<any> {
    return this.http.delete(ApiUrls.VisionProject.deleteById(id));
  }

  createVisionProject(
    userId: string,
    projectId: string,
  ): Observable<VisionProject> {
    return this.http.post<VisionProject>(ApiUrls.VisionProject.post(), {
      user__id: userId,
      project__id: projectId,
    });
  }

  paginatedProjects(
    search: string,
    params?: {
      page: number;
      pageSize: number;
      sortModel?: { colId: string; sort: 'asc' | 'desc' }[];
      groupKeys?: string[];
      rowGroupCols?: ColumnVO[];
      valueCols?: ColumnVO[];
    },
  ): Observable<ResponseRows<Project>> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('general', search ?? '');

    if (params?.page !== undefined) {
      queryParams = queryParams.append('page', params.page.toString());
    }

    if (params?.pageSize !== undefined) {
      queryParams = queryParams.append('pageSize', params.pageSize.toString());
    }

    if (params?.sortModel !== undefined) {
      queryParams = queryParams.append(
        'sortModel',
        JSON.stringify(params?.sortModel),
      );
    }

    if (params?.groupKeys !== undefined) {
      queryParams = queryParams.append(
        'groupKeys',
        JSON.stringify(params?.groupKeys),
      );
    }

    if (params?.rowGroupCols !== undefined) {
      queryParams = queryParams.append(
        'rowGroupCols',
        JSON.stringify(params?.rowGroupCols),
      );
    }

    if (params?.valueCols !== undefined) {
      queryParams = queryParams.append(
        'valueCols',
        JSON.stringify(params?.valueCols),
      );
    }

    return this.http.get<ResponseRows<Project>>(
      `${this.baseEndPoint}/paginated`,
      {
        params: queryParams,
      },
    );
  }

  togglePin(projectId: string): Observable<Project> {
    return this.http.put<Project>(
      `${this.baseEndPoint}/${projectId}/toggle-pin`,
      {},
    );
  }

  fetchVisionView(
    pageSize: number = 15,
    cursor: {
      pinned?: boolean;
      created_at?: string;
      _id?: string;
    } = {},
    search: string = '',
    refresh: boolean = false,
    excludeIds: string[] = [],
    entityId: string | undefined,
    projectsScope?: ProjectsScope,
  ): Observable<IVisionProjectViewEntity[]> {
    return this.http
      .get<IVisionProjectViewResponse[]>(
        `${environment.apiUrl}/projects/vision`,
        {
          params: {
            cursor: encodeURIComponent(JSON.stringify(cursor)),
            pageSize: pageSize.toString(),
            search,
            refresh: refresh.toString(),
            excludeIds: JSON.stringify(excludeIds),
            ...(entityId && { entityId }),
            ...(projectsScope && { projectsScope }),
          },
        },
      )
      .pipe(
        map((response) => {
          return _map(response, (entity: IVisionProjectViewResponse) => {
            return { ...entity, entityType: entity.entity_type };
          });
        }),
      );
  }

  fetchProjectInfo(projectId: string, params: ProjectInfoQueryDTO) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append(
      'include_water_concerns',
      JSON.stringify(params.include_water_concerns),
    );
    return this.http.get<SiteSelectionInfoResponseDTO>(
      `${environment.apiUrl}/projects/${projectId}/info`,
      { params: queryParams },
    );
  }

  fetchProjectParcels(
    projectId: string,
    params?: {
      search?: string;
      sortModel?: {
        column: string;
        order: 'asc' | 'desc' | null | undefined;
      }[];
      includeWatersConcernLevel: boolean;
      page?: number;
      pageSize?: number;
      filterModel?: {
        key: string;
        value: string;
      }[];
    },
  ): Observable<LandownerContactWithParcelFilterParcelsResponseDTO> {
    let queryParams = new HttpParams();
    queryParams = queryParams
      .append('sortModel', JSON.stringify(params?.sortModel))
      .append(
        'includeWatersConcernLevel',
        JSON.stringify(params?.includeWatersConcernLevel),
      )
      .append('search', params?.search ?? '');

    if (params?.page && params?.pageSize) {
      queryParams = queryParams
        .append('page', params.page.toString())
        .append('pageSize', params.pageSize.toString());
    }
    if (params?.filterModel) {
      queryParams = queryParams.append(
        'filterModel',
        JSON.stringify(params.filterModel),
      );
    }
    return this.http
      .get<LandownerContactWithParcelFilterParcelsResponseDTO>(
        `${environment.apiUrl}/projects/${projectId}/parcels`,
        {
          params: queryParams,
        },
      )
      .pipe(
        map((response) =>
          LandownerContactWithParcelFilterParcelsResponseDTO.parse(response),
        ),
      );
  }

  updateProjectName(projectId: string, updatedProjectName: string) {
    const requestBody = {
      name: updatedProjectName,
    };
    return this.http.patch(
      `${environment.apiUrl}/projects/${projectId}/name`,
      requestBody,
    );
  }

  updateProjectStatus(projectId: string, status: ProjectStatus) {
    const requestBody = {
      status,
    };
    return this.http.put(
      `${environment.apiUrl}/projects/${projectId}`,
      requestBody,
    );
  }

  fetchProjectGeometry(
    projectId: string,
  ): Observable<VisionProjectGeometryDTO> {
    return this.http.get<VisionProjectGeometryDTO>(
      `${environment.apiUrl}/projects/${projectId}/geometry`,
    );
  }

  fetchMultipleProjectGeometry(
    query: GetProjectsMultipleWithGeometryQueryDTO,
  ): Observable<VisionProjectsMultipleWithGeometryDTO> {
    return this.http.get<VisionProjectsMultipleWithGeometryDTO>(
      `${environment.apiUrl}/projects/geometry/multiple`,
      {
        params: query,
      },
    );
  }

  validMarketplaceServiceArea(project: {
    in_united_states?: boolean;
    geographies_states?: { abbreviation?: string }[];
  }): boolean {
    if (!project || !project.in_united_states) {
      return false;
    }

    if (!project.geographies_states) {
      return false;
    }

    const states = project.geographies_states.map(
      (state) => state.abbreviation,
    );

    if (
      states.includes('PR') ||
      states.includes('AK') ||
      states.includes('HI')
    ) {
      return false;
    }

    return true;
  }

  fetchProblematicLCIParcelsCount(projectId: string) {
    return this.http
      .get<GetProblematicLCIParcelCountResponseDTO>(
        `${environment.apiUrl}/projects/${projectId}/problematic-lci-parcels/count`,
      )
      .pipe(
        map((response) => {
          return GetProblematicLCIParcelCountResponseDTO.parse(response);
        }),
      );
  }

  archiveOrRestoreProject(
    projectIds: string[],
    toArchive: boolean,
  ): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/projects/archive-or-restore`,
      {
        projectIds,
        toArchive,
      },
    );
  }
}
